import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { SummaryTable } from '../summaryTable'
import { Columns } from './Constants'

const PerCountyRegion = () => {
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState<File[]>([]);

async function fetchData() {
  try {
    setLoading(true);
    const response = await axios.get('http://194.163.134.15:8007/api/v2/arwp/report/summary/per-county-region');
    const dataArray = response.data || []; // Check if data is defined
    setDataSource(dataArray);
  } catch (error) {
    console.error('Error fetching data:', error);
  } finally {
    setLoading(false);
  }
}

useEffect(() => {
  fetchData(); // Fetch data when the component mounts
}, []);
  return (
    <SummaryTable columns={Columns} dataSource={dataSource}/>
  )
}

export default PerCountyRegion
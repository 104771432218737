import React from 'react';
import { Container } from 'reactstrap';

import BreadCrumb from '../../../Components/Common/BreadCrumb';


const Allocations = () => {
  document.title = 'Allocations | APRPMS'; //for meta title
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb
            title="Allocations"
            breadcrumbItem="Allocations"
          />




          
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Allocations;

import React from 'react';
import { Col } from 'reactstrap';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Link } from 'react-router-dom';

// Import Images
import logoLight from '../../assets/images/krb.png';

const AuthSlider = () => {
  return (
    <React.Fragment>
      <Col lg={6}>
        <div className="p-lg-5 p-4 auth-one-bg h-100">
          <div className="bg-overlay"></div>
          <div className="position-relative h-100 d-flex flex-column">
            <div className="mb-4 mx-auto">
              <Link
                to="/dashboard"
                className="d-block">
                <img
                  src={logoLight}
                  alt="aprpms"
                />
              </Link>
            </div>
            <div className="text-center mx-auto text-primary">
              <h1 className='text-secondary'>APRPMS</h1>
              <h3 className='text-secondary'>Annual Public Roads Programme Management System</h3>
            </div>
          </div>
        </div>
      </Col>
    </React.Fragment>
  );
};

export default AuthSlider;

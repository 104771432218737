/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { Link } from "react-router-dom";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import BudgetCeilingListTable from "./BudgetCeilingListTable";
import { BUDGETCEILINGLISTCOLUMNS } from "./Constants";
import axios from "axios";

interface Directorate {
  name: string;
  directorateId: number;
}

interface Department {
  departmentId: number;
  name: string;
  // other properties
}

const BudgetCeilingEstimate: React.FC = () => {
  const [modalList, setModalList] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);

  function toggleList() {
    setModalList(!modalList);
  }

  function toggleDelete() {
    setModalDelete(!modalDelete);
  }

  const [data, setData] = useState<any>(null);
  const [error, setError] = useState<any>(null);
  const [count, setCount] = useState<number>(0);

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    try {
      const response = await axios.get(
        `http://194.163.134.15:8013/api/Disbursement/GetAllBudgetCeilingEstimates`
      );
      const dataArray = response.data;
      const dataCount = dataArray.length;
      setData(dataArray);
      setCount(dataCount);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const [filterTable, setFilterTable] = useState<any>(null);
  const [currentDataSource, setCurrentDataSource] = useState<any>(data);

  useEffect(() => {
    setCurrentDataSource(data);
  }, [data]);

  const initialFormData = {
    DirectorateId: "",
    DepartmentId: "",
    FirstName: "",
    MiddleName: "",
    LastName: "",
    EmailAddress: "",
    NationalID: "",
    MobileNo: "",
    Designation: "",
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "http://194.163.134.15:8003/api/UserManagement/AccessListAdd",
        formData
      );

      if (response.status === 200) {
        console.log("User added successfully");
        setFormData(initialFormData);
      } else {
        console.error("Error adding user:", response.data);
      }
    } catch (error) {
      console.error("Error adding user:", error);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <CardHeader>
              <h4 className="card-title mb-0">Budget Ceiling Estimate List</h4>
            </CardHeader>
            <CardBody>
              <div id="customerList">
                <Row className="g-4">
                  <Col className="col-sm-auto">
                    <div>
                      <Button
                        color="success"
                        className="add-btn me-1"
                        onClick={() => toggleList()}
                        id="create-btn"
                      >
                        <i className="ri-add-line align-bottom me-1"></i> Add
                      </Button>
                      <Button
                        color="primary"
                        className="add-btn me-1"
                        onClick={() => toggleList()}
                        id="create-btn"
                      >
                        <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                        Sync
                      </Button>
                    </div>
                  </Col>
                  <Col className="col-sm">
                    <div className="d-flex justify-content-sm-end">
                      <div className="search-box ms-2">
                        <input
                          type="text"
                          className="form-control search"
                          placeholder="Search..."
                        />
                        <i className="ri-search-line search-icon"></i>
                      </div>
                    </div>
                  </Col>
                </Row>

                <div className="table-responsive table-card mb-1">
                  <div className="table align-middle table-nowrap">
                    <BudgetCeilingListTable
                      columns={BUDGETCEILINGLISTCOLUMNS}
                      dataSource={currentDataSource || []}
                    />
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal
        isOpen={modalList}
        toggle={() => {
          toggleList();
        }}
        centered
      >
        <ModalHeader
          className="bg-light p-3"
          toggle={() => {
            toggleList();
          }}
        >
          Add Budget Ceiling Estimate(s)
        </ModalHeader>
        <form className="tablelist-form" onSubmit={handleSubmit}>
          <ModalBody>
            <div className="mb-3">
              <label htmlFor="financialyear-field" className="form-label">
                Financial Year
              </label>
              <input
                type="text"
                id="financialyear-field"
                className="form-control"
                placeholder="Enter Financial Year"
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="description-field" className="form-label">
                Description
              </label>
              <input
                type="text"
                id="description-field"
                className="form-control"
                placeholder="Enter Description"
              />
            </div>

            <div className="mb-3">
              <label htmlFor="fuellevyamount-field" className="form-label">
                Fuel Levy Amount
              </label>
              <input
                type="text"
                id="fuellevyamount-field"
                className="form-control"
                placeholder="Enter Fuel Levy Amount"
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="email-field" className="form-label">
                Transit Tolls Amount
              </label>
              <input
                type="text"
                id="transittollsamount-field"
                className="form-control"
                placeholder="Enter Transit Tolls Amount"
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="pno-field" className="form-label">
                Savings
              </label>
              <input
                type="text"
                id="savings-field"
                className="form-control"
                placeholder="Savings"
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="mobile-field" className="form-label">
                Total Funds Available
              </label>
              <input
                type="text"
                id="totalfundsavailable-field"
                className="form-control"
                placeholder="Enter Total Funds Available"
                required
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setModalList(false)}
              >
                Close
              </button>
              <button type="submit" className="btn btn-success" id="add-btn">
                Add Budget Ceiling Estimate
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>
    </React.Fragment>
  );
};

export default BudgetCeilingEstimate;
/* eslint-enable no-restricted-globals */

import React from 'react';
import { Container } from "reactstrap";

//Import Breadcrumb
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { APRPUpload } from './uploadFile';
import { APRPList } from './listFiles';


const APRPImplementation = () => {
        document.title = "APRP Implementaion | APRPMS";   //for meta title
        return (
            <>
                <div className="page-content">
                    <Container fluid={true}>
                        <BreadCrumb title="APRP Implementaion" breadcrumbItem="APRP Implementaion" />
                        
                        <APRPUpload/>
                        
                        <APRPList/>
                    </Container>
                </div>
            </>
        );
    }

export default APRPImplementation;
// import moment from 'moment';
import React from "react";
import Table from "../../../Components/Tables/Table";
// import { useSelector } from 'react-redux';

interface Props {
  columns: any[];
  dataSource: any[];
}

const BudgetCeilingListTable: React.FC<Props> = (props) => {
  const { columns, dataSource } = props;

  const formatData = (data: any) =>
    data.map((item: any, index: number) => {
      return {
        ...item,
        key: index,
      };
    });

  const allColumns = [...columns];
  return (
    <Table
      dataSource={formatData(dataSource.sort((a, b) => b.id - a.id))}
      columns={allColumns}
    />
  );
};

export default BudgetCeilingListTable;

import React from 'react';
import { Container } from 'reactstrap';

//Import Breadcrumb
import BreadCrumb from '../../../Components/Common/BreadCrumb';

const Disbursement = () => {
  document.title = 'DISBURSEMENT | APRPMS'; //for meta title
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb
            title="DISBURSEMENT"
            breadcrumbItem="DISBURSEMENT"
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Disbursement;

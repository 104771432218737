import React from "react";
import { Container } from "reactstrap";

//Import Breadcrumb
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import BudgetCeilingEstimate from "./BudgetCeilingEstimate2";

const BudgetingAndForecasting = () => {
  document.title = "Budgeting And Forecasting | APRPMS"; //for meta title
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb
            title="BUDGET CEILING ESTIMATES"
            breadcrumbItem="BUDGET CEILING ESTIMATES"
          />

          {/* Code goes */}
          <BudgetCeilingEstimate/>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default BudgetingAndForecasting;

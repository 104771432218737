/* eslint-disable no-restricted-globals */
import React from 'react';
import { Tag, Dropdown, Menu, Modal } from 'antd';
import { Link } from 'react-router-dom';
import { useDeleteModal } from '../../../deleteModal/useDeleteModal';

interface File {
  id: number;
  fileName: string;
  authority: string;
  financialYear: string;
  fundingSource: string;
  fundType: string;
  budget: number;
  status: string;
}

export const FILESCOLUMNS = [
  {
    title: 'File Name',
    dataIndex: 'fileName',
    key: 'fileName',
    sorter: (a: File, b: File) => a.fileName.localeCompare(b.fileName),
  },
   {
    title: 'Authority',
    dataIndex: 'authority',
    key: 'authority',
    sorter: (a: File, b: File) => a.authority.localeCompare(b.authority),
  },
  {
    title: 'Financial Year',
    dataIndex: 'financialYear',
    key: 'financialYear',
    sorter: (a: File, b: File) => a.financialYear.localeCompare(b.financialYear),
  },
  {
    title: 'Funding Source',
    dataIndex: 'fundingSource',
    key: 'fundingSource',
    sorter: (a: File, b: File) => a.fundingSource.localeCompare(b.fundingSource),
  },
  {
    title: 'Fund Type',
    dataIndex: 'fundType',
    key: 'fundType',
    sorter: (a: File, b: File) => a.fundType.localeCompare(b.fundType),
  },
  {
    title: 'Budget',
    dataIndex: 'budget',
    key: 'budget',
    sorter: (a: File, b: File) => a.budget - b.budget,
    render: (budget: number) => `$${budget.toFixed(2)}`,
  },
  {
    title: 'Actions',
    key: 'actions',
    render: (text: string, record: File) => <ActionModalComponent text={text} record={record} />
  },
];

const ActionModalComponent = ({ text, record }: { text: string, record: File }) => {
  const {
    isDeleteModalVisible,
    showDeleteModal,
    hideDeleteModal,
  } = useDeleteModal();

  const handleDeleteConfirm = async () => {
    try {
      const response = await fetch(`http://194.163.134.15:8007/api/v1/arwp/delete/${record.id}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error('Delete request failed');
      }

      hideDeleteModal();
    } catch (error) {
      console.error('Failed to delete file:', error);
    }
  };

  return (
    <>
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item key="view">
              <div className="dropdown-menu-item">
                <Link to={`/planning/arwp/detail-view/${record.id}`}>
                  View
                </Link>
                <i color='' className="ri-eye-line"></i>
              </div>
            </Menu.Item>
            <Menu.Item key="delete">
              <div className="dropdown-menu-item">
                <a onClick={showDeleteModal}>Delete</a>
                <i className="ri-delete-bin-line"></i>
              </div>
            </Menu.Item>
            <Menu.Item key="download">
              <div className="dropdown-menu-item">
                <Link to={`http://194.163.134.15:8007/api/v1/arwp/download/${record.id}`}>
                  Download
                </Link>
                <i className="ri-download-2-line"></i>
              </div>
            </Menu.Item>
          </Menu>
        }
      >
        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <circle cx="8" cy="4" r="1" />
            <circle cx="8" cy="8" r="1" />
            <circle cx="8" cy="12" r="1" />
          </svg>
        </a>
      </Dropdown>

      <Modal
        title="Confirm Delete"
        visible={isDeleteModalVisible}
        onOk={handleDeleteConfirm}
        onCancel={hideDeleteModal}
        okText="Delete"
        cancelText="Cancel"
        okButtonProps={{ style: { backgroundColor: '#049948 ', borderColor: '#049948 ' } }}
      >
        Are you sure you want to delete this file?
      </Modal>
    </>
  );
};


import React from 'react';
import { Space, Table, Tag, Dropdown, Menu } from 'antd';
interface GroupListViewModel {
    id: string;
    group_name: string;
    description: string;
  }

  export const GROUPSCOLUMNS = [
    // {
    //   title: 'ID',
    //   key: 'id',
    //   dataIndex: 'id',
    //   sorter: (a: GroupListViewModel, b: GroupListViewModel) => a.id.localeCompare(b.id),
    // },
    {
      title: 'Group Name',
      key: 'group_name',
      dataIndex: 'group_name',
      sorter: (a: GroupListViewModel, b: GroupListViewModel) => a.group_name.localeCompare(b.group_name),
    },
    {
      title: 'Description',
      key: 'description',
      dataIndex: 'description',
      sorter: (a: GroupListViewModel, b: GroupListViewModel) => a.description.localeCompare(b.description),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text: string, record: GroupListViewModel) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="edit">
                <div className="dropdown-menu-item">
                  <a href={`http://example.com/edit/${record.id}`} target="_blank" rel="noopener noreferrer">
                    Edit
                  </a>
                  <i color='' className="ri-edit-2-line"></i>
                </div>
              </Menu.Item>
              <Menu.Item key="remove">
                <div className="dropdown-menu-item">
                  <a href={`http://example.com/delete/${record.id}`} target="_blank" rel="noopener noreferrer">
                    Remove
                  </a>
                  <i className="ri-delete-bin-line"></i>
                </div>
              </Menu.Item>
            </Menu>
          }
        >
          <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
              <circle cx="8" cy="4" r="1" />
              <circle cx="8" cy="8" r="1" />
              <circle cx="8" cy="12" r="1" />
            </svg>
          </a>
        </Dropdown>
      ),
    },
  ];
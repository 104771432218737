/* eslint-disable no-restricted-globals */
import React from 'react';
import { Tag, Dropdown, Menu, Modal } from 'antd';
import { Link } from 'react-router-dom';
import { useDeleteModal } from '../../../deleteModal/useDeleteModal';
import { ColumnProps } from 'antd/lib/table';
interface DataItem {
  id: number;
  fileName: string;
  fileData: string;
  reportDate: string;
  creationDate: string;
  createdBy: string;
  financialYear: string;
  authorityCode: string;
  authorityName: string;
  actions: any;
  // Add other properties as needed
}

export const columns: ColumnProps<DataItem>[] = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sorter: (a, b) => a.id - b.id,
  },
  // Define your columns for the main table here
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sorter: (a, b) => a.id - b.id,
  },
  {
    title: 'File Name',
    dataIndex: 'fileName',
    key: 'fileName',
    sorter: (a, b) => a.fileName.localeCompare(b.fileName),
  },
  {
    title: 'File Data',
    dataIndex: 'fileData',
    key: 'fileData',
    sorter: (a, b) => a.fileData.localeCompare(b.fileData),
  },
  {
    title: 'Report Date',
    dataIndex: 'reportDate',
    key: 'reportDate',
    sorter: (a, b) => a.reportDate.localeCompare(b.reportDate),
  },
  {
    title: 'Creation Date',
    dataIndex: 'creationDate',
    key: 'creationDate',
    sorter: (a, b) => a.creationDate.localeCompare(b.creationDate),
  },
  {
    title: 'Created By',
    dataIndex: 'createdBy',
    key: 'createdBy',
    sorter: (a, b) => a.createdBy.localeCompare(b.createdBy),
  },
  {
    title: 'Financial Year',
    dataIndex: 'financialYear',
    key: 'financialYear',
    sorter: (a, b) => a.financialYear.localeCompare(b.financialYear),
  },
  {
    title: 'Authority Code',
    dataIndex: 'authorityCode',
    key: 'authorityCode',
    sorter: (a, b) => a.authorityCode.localeCompare(b.authorityCode),
  },
  {
    title: 'Authority Name',
    dataIndex: 'authorityName',
    key: 'authorityName',
    sorter: (a, b) => a.authorityName.localeCompare(b.authorityName),
  },
  {
    title: 'Actions',
    key: 'actions',
    render: (text , record) => (
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item key="edit">
              <div className="dropdown-menu-item">
                <a href={`http://example.com/edit/${record.id}`} target="_blank" rel="noopener noreferrer">
                  View
                </a>
                <i color='' className="ri-edit-2-line"></i>
              </div>
            </Menu.Item>
            {/* <Menu.Item key="remove">
              <div className="dropdown-menu-item">
                <a href={`http://example.com/delete/${record.id}`} target="_blank" rel="noopener noreferrer">
                  Remove
                </a>
                <i className="ri-delete-bin-line"></i>
              </div>
            </Menu.Item> */}
          </Menu>
        }
      >
        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <circle cx="8" cy="4" r="1" />
            <circle cx="8" cy="8" r="1" />
            <circle cx="8" cy="12" r="1" />
          </svg>
        </a>
      </Dropdown>
    ),
  }
];


export const LOGIN_USER = "LOGIN_USER"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"

export const LOGOUT_USER = "LOGOUT_USER"
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"
export const API_ERROR = "LOGIN_API_ERROR"

export const SOCIAL_LOGIN = "SOCIAL_LOGIN"

export const RESET_LOGIN_FLAG = "RESET_LOGIN_FLAG"

export const  LOGIN_USER_OTP_REQUIRED = " LOGIN_USER_OTP_REQUIRED,"
// actionTypes.ts
export const OTP_VERIFICATION_SUCCESS = "OTP_VERIFICATION_SUCCESS";

// actionTypes.ts
export const OTP_VERIFICATION_FAILURE = "OTP_VERIFICATION_FAILURE";

import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  API_ERROR,
  SOCIAL_LOGIN,
  RESET_LOGIN_FLAG,
  LOGIN_USER_OTP_REQUIRED,
  OTP_VERIFICATION_SUCCESS,
  OTP_VERIFICATION_FAILURE,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
} from "./actionTypes";
import { User } from "./actions";

const initialState = {
  user: null,
  errorMsg: null,
  loading: false,
  error: false,
  otpRequired: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        loading: true,
        error: false,
        otpRequired: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        user: action.payload,
      };
    case API_ERROR:
      return {
        ...state,
        errorMsg: action.payload,
        loading: false,
        error: true,
      };
    case SOCIAL_LOGIN:
      // Handle social login if needed
      return state;
    case RESET_LOGIN_FLAG:
      return {
        ...state,
        errorMsg: null,
        loading: false,
        error: false,
      };
    case LOGIN_USER_OTP_REQUIRED:
      return {
        ...state,
        otpRequired: true,
      };
    case OTP_VERIFICATION_SUCCESS:
      // Handle OTP verification success if needed
      return {
        ...state,
        otpRequired: false, // Reset OTP requirement flag on success
      };
    case OTP_VERIFICATION_FAILURE:
      // Handle OTP verification failure if needed
      return {
        ...state,
        otpRequired: false, // Reset OTP requirement flag on failure
      };
    case LOGOUT_USER:
      // Handle logout if needed
      return state;
    case LOGOUT_USER_SUCCESS:
      // Handle logout success if needed
      return state;
    default:
      return state;
  }
};

export default authReducer;

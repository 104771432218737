import React from 'react'
import BreadCrumb from '../../../../Components/Common/BreadCrumb';
import { Button, Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import WorkPlanActivitiesList from './WorkPlanActivitiesList';

const WorkPlanActivities = () => {
  document.title = "Workplan Activities | APRPMS ";   //for meta title
  return (
      <>
          <div className="page-content">
              <Container fluid={true}>
                  <BreadCrumb title="Workplan Activities" breadcrumbItem="Workplan Activities" />
                  <Row>
              <Col lg={12}>
                <Card>
                  <CardHeader>
                    <h4 className="card-title mb-0">Workplan Activities</h4>
                  </CardHeader>

                  <CardBody>
                    <div id="customerList">
                      <Row className="g-4 mb-3 mt-1">
                        header content here
                      </Row>

                      <div className="table-responsive table-card mt-3 mb-1">
                        <div className="table align-middle table-nowrap">
                         <WorkPlanActivitiesList/> 
                        </div>
                 
                      </div>

                   
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

              </Container>
          </div>
      </>
  );
}

export default WorkPlanActivities
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import WorkplanTable from './WorkplanTable';
import { ACTIVITYCOLUMNS } from './Constants';
import { useParams } from 'react-router-dom';

const WorkPlanActivitiesList = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    // Define fetchData as an async function
    async function fetchData() {
      try {
        setLoading(true);
        // Use template literals to construct the API URL
        const response = await axios.get(`http://194.163.134.15:8007/api/v2/arwp/workplan/${id}`);
        // Check if response data exists and has workplanActivities property
        const genDataArray = response.data || [];
        const dataArray = response.data.workplanActivities || [];
        setDataSource(dataArray);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    }

    // Call fetchData within the useEffect callback
    fetchData();
  }, [id]);

  return (
 
      
        <WorkplanTable parentColumns={ACTIVITYCOLUMNS} dataSource={dataSource} />
   
 
  );
};

export default WorkPlanActivitiesList;

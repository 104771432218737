import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";

// Replace with your custom authentication URLs
const CUSTOM_AUTH_URL = "http://194.163.134.15:8003/api/Auth/Login";
const SOCIAL_AUTH_URL = "YOUR_SOCIAL_AUTH_URL";

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(fetch, CUSTOM_AUTH_URL, {
      method: 'POST',
      body: JSON.stringify({ username: user.username, password: user.password }),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const data = yield response.json();

    if (response.ok) {
      yield put(loginSuccess(data));
      history.push('/dashboard'); // Correctly navigates to the dashboard page
    } else {
      yield put(apiError(data));
    }
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function* logoutUser() {
  try {
    // Replace with your custom logout logic
    yield put(logoutUserSuccess(LOGOUT_USER, true));
  } catch (error) {
    yield put(apiError(LOGOUT_USER, error));
  }
}

// function* socialLogin({ payload: { data, history, type } }) {
//   try {
//     // Replace with your custom social login logic and URL
//     const response = yield call(fetch, SOCIAL_AUTH_URL, {
//       method: "POST",
//       body: JSON.stringify(data),
//       headers: {
//         "Content-Type": "application/json",
//       },
//     });

//     const responseData = yield response.json();

//     if (response.ok) {
//       history("/dashboard");
//     } else {
//       history("/login");
//     }

//     sessionStorage.setItem("authUser", JSON.stringify(responseData));
//     yield put(loginSuccess(responseData));
//   } catch (error) {
//     yield put(apiError(error));
//   }
// }

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  // yield takeLatest(SOCIAL_LOGIN, socialLogin);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;

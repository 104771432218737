import React, { useEffect } from "react";
import { Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback, Button } from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// action
import { registerUser, apiError, resetRegisterFlag } from "../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link, useNavigate } from "react-router-dom";

//import images 
import logoLight from "../../assets/images/logo-light.png";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { createSelector } from "reselect";

const Register = () => {
    const history = useNavigate();
    const dispatch = useDispatch();

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            email: '',
            first_name: '',
            password: '',
            confirm_password: ''
        },
        validationSchema: Yup.object().shape({
            email: Yup.string()
                .email('Please enter a valid email address')
                .required('Please enter your email'),
            first_name: Yup.string().required('Please enter your username'),
            password: Yup.string()
                .min(6, 'Password must be at least 6 characters')
                .required('Password is required'),
            confirm_password: Yup.string()
                .oneOf([Yup.ref('password'), null], 'Passwords must match')
                .required('Confirm password is required')
        }),
        onSubmit: (values) => {
            dispatch(registerUser(values));
        }
    });

    const selectLayoutState = (state) => state.Account;
    const registerData = createSelector(
      selectLayoutState,
      (accountState) => ({
        registrationError: accountState.registrationError,
        success: accountState.success,
        error: accountState.error
      })
    );

  // Inside your component
const {
    registrationError,
    success,
    error
} = useSelector(registerData);

    useEffect(() => {
        dispatch(apiError(""));
    }, [dispatch]);

    useEffect(() => {
        if (success) {
            setTimeout(() => history("/login"), 3000);
        }

        setTimeout(() => {
            dispatch(resetRegisterFlag());
        }, 3000);

    }, [dispatch, success, error, history]);

    document.title = 'Basic SignUp | APRPMS';

    return (
      <React.Fragment>
        <ParticlesAuth>
          <div className="auth-page-content">
            <Container>
              <Row>
                <Col lg={12}>
                  <div className="text-center mt-sm-5 mb-4 text-white-50">
                    <div>
                      <Link
                        to="/"
                        className="d-inline-block auth-logo">
                        <img
                          src={logoLight}
                          alt=""
                          height="20"
                        />
                      </Link>
                    </div>
                    <p className="mt-3 fs-15 fw-medium">
                      Annual Public Roads Programme Management System
                    </p>
                  </div>
                </Col>
              </Row>

              <Row className="justify-content-center">
                <Col
                  md={8}
                  lg={6}
                  xl={5}>
                  <Card className="mt-4">
                    <CardBody className="p-4">
                      <div className="text-center mt-2">
                        <h5 className="text-primary">Create New Account</h5>
                        <p className="text-muted">
                          Get your free APRPMS account now
                        </p>
                      </div>
                      <div className="p-2 mt-4">
                        <Form
                          onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}
                          className="needs-validation"
                          action="#">
                          {success && success ? (
                            <>
                              {toast('Your Redirect To Login Page...', {
                                position: 'top-right',
                                hideProgressBar: false,
                                className: 'bg-success text-white',
                                progress: undefined,
                                toastId: '',
                              })}
                              <ToastContainer
                                autoClose={2000}
                                limit={1}
                              />
                              <Alert color="success">
                                Register User Successfully and Your Redirect To
                                Login Page...
                              </Alert>
                            </>
                          ) : null}

                          {error && error ? (
                            <Alert color="danger">
                              <div>
                                {/* {registrationError} */}
                                Email has been Register Before, Please Use
                                Another Email Address...{' '}
                              </div>
                            </Alert>
                          ) : null}

                          <div className="mb-3">
                            <Label
                              htmlFor="useremail"
                              className="form-label">
                              Email <span className="text-danger">*</span>
                            </Label>
                            <Input
                              id="email"
                              name="email"
                              className="form-control"
                              placeholder="Enter email address"
                              type="email"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ''}
                              invalid={
                                validation.touched.email &&
                                validation.errors.email
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.email &&
                            validation.errors.email ? (
                              <FormFeedback type="invalid">
                                <div>{validation.errors.email}</div>
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-3">
                            <Label
                              htmlFor="username"
                              className="form-label">
                              Username <span className="text-danger">*</span>
                            </Label>
                            <Input
                              name="first_name"
                              type="text"
                              placeholder="Enter username"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.first_name || ''}
                              invalid={
                                validation.touched.first_name &&
                                validation.errors.first_name
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.first_name &&
                            validation.errors.first_name ? (
                              <FormFeedback type="invalid">
                                <div>{validation.errors.first_name}</div>
                              </FormFeedback>
                            ) : null}
                          </div>

                          <div className="mb-3">
                            <Label
                              htmlFor="userpassword"
                              className="form-label">
                              Password <span className="text-danger">*</span>
                            </Label>
                            <Input
                              name="password"
                              type="password"
                              placeholder="Enter Password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.password || ''}
                              invalid={
                                validation.touched.password &&
                                validation.errors.password
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.password &&
                            validation.errors.password ? (
                              <FormFeedback type="invalid">
                                <div>{validation.errors.password}</div>
                              </FormFeedback>
                            ) : null}
                          </div>

                          <div className="mb-2">
                            <Label
                              htmlFor="confirmPassword"
                              className="form-label">
                              Confirm Password{' '}
                              <span className="text-danger">*</span>
                            </Label>
                            <Input
                              name="confirm_password"
                              type="password"
                              placeholder="Confirm Password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.confirm_password || ''}
                              invalid={
                                validation.touched.confirm_password &&
                                validation.errors.confirm_password
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.confirm_password &&
                            validation.errors.confirm_password ? (
                              <FormFeedback type="invalid">
                                <div>{validation.errors.confirm_password}</div>
                              </FormFeedback>
                            ) : null}
                          </div>

                          <div className="mb-4">
                            <p className="mb-0 fs-12 text-muted fst-italic">
                              By registering you agree to the APRPMS
                              <Link
                                to="#"
                                className="text-primary text-decoration-underline fst-normal fw-medium">
                                Terms of Use
                              </Link>
                            </p>
                          </div>

                          <div className="mt-4">
                            <button
                              className="btn btn-success w-100"
                              type="submit">
                              Sign Up
                            </button>
                          </div>

                          <div className="mt-4 text-center">
                            <div className="signin-other-title">
                              <h5 className="fs-13 mb-4 title text-muted">
                                Create account with
                              </h5>
                            </div>

                            <div>
                              <button
                                type="button"
                                className="btn btn-primary btn-icon waves-effect waves-light">
                                <i className="ri-facebook-fill fs-16"></i>
                              </button>{' '}
                              <button
                                type="button"
                                className="btn btn-danger btn-icon waves-effect waves-light">
                                <i className="ri-google-fill fs-16"></i>
                              </button>{' '}
                              <button
                                type="button"
                                className="btn btn-dark btn-icon waves-effect waves-light">
                                <i className="ri-github-fill fs-16"></i>
                              </button>{' '}
                              <button
                                type="button"
                                className="btn btn-info btn-icon waves-effect waves-light">
                                <i className="ri-twitter-fill fs-16"></i>
                              </button>
                            </div>
                          </div>
                        </Form>
                      </div>
                    </CardBody>
                  </Card>
                  <div className="mt-4 text-center">
                    <p className="mb-0">
                      Already have an account ?{' '}
                      <Link
                        to="/login"
                        className="fw-semibold text-primary text-decoration-underline">
                        {' '}
                        Signin{' '}
                      </Link>{' '}
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </ParticlesAuth>
      </React.Fragment>
    );
};

export default Register;

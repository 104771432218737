import React from 'react';
import Table from '../../../../Components/Tables/Table';

interface Props {
  parentColumns: any[];
  nestedColumns?: any[]; // Add a ? to make it optional
  dataSource: any[];
}

const ARWPFilesTable: React.FC<Props> = ({ parentColumns, nestedColumns, dataSource }) => {
  const formatData = (data: any) =>
    data.map((item: any, index: number) => ({
      ...item,
      key: index,
    }));

  // Define columns for the parent table
  const parentTableColumns = [
    ...parentColumns,
  ];

  // Define inline styles to remove padding and margin
  const noMarginPaddingStyle = {
    margin: 0,
    padding: 0,
  };

  return (
    <Table
      dataSource={formatData(dataSource)}
      columns={parentTableColumns}
      expandable={{
        expandedRowRender: (record) => (
          nestedColumns && nestedColumns.length > 0 ? (
            <Table
              columns={nestedColumns}
              dataSource={record.files}
             
              style={noMarginPaddingStyle} // Apply inline style
              bordered={false}
            />
          ) : null
        ),
        // Conditionally render the expand button based on the presence of nestedColumns
        rowExpandable: (record) => Boolean(record.files && record.files.length > 0),
      }}
    />
  );
};

export default ARWPFilesTable;

import React, { useEffect } from "react";
import { Navigate, Route } from "react-router-dom";
import { setAuthorization } from "../helpers/api_helper";
import { useDispatch } from "react-redux";

import { useProfile } from "../Components/Hooks/UserHooks";

import { logoutUser } from "../store/actions";

// @ts-ignore
const AuthProtected = (props) => {
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  // @ts-ignore
  const { userProfile, loading} = useProfile();

  useEffect(() => {
    if (token) {
      setAuthorization(token);
      // Read the token here to acknowledge that the user is logged in
      console.log("User is logged in. Token:", token);
    } else {
      dispatch(logoutUser());
    }
  }, [token, dispatch]);
  
 

  /*
    Navigate is un-auth access protected routes via url
    */

  if (loading && !token) {
    return (
      // @ts-ignore
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    );
  }

  return <>{props.children}</>;
};

// @ts-ignore
const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      // @ts-ignore
      render={props => {
        return (<> <Component {...props} /> </>);
      }}
    />
  );
};

export { AuthProtected, AccessRoute };
import React from 'react'
import { Container } from 'reactstrap';
import BreadCrumb from '../../../../Components/Common/BreadCrumb';
import SummariesTables from './SummariesTables';

const Summaries = () => {
    document.title = "ARWP Summaries | Admin & Dashboard Template";   //for meta title
    return (
        <>
            <div className="page-content">
                <Container fluid={true}>
                    <BreadCrumb title="ARWP Summaries" breadcrumbItem="ARWP Summaries" />
                    
                      <SummariesTables/>

                </Container>
            </div>
        </>
    );
}

export default Summaries
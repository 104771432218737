import React, { useState } from 'react';
import Select from 'react-select';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';

// Import your table components (assuming these imports are correct)
import { PerAgency } from './perAgency';
import { PerCountyRegion } from './perCountyRegion';
import { CountyRegion } from './countryRegion';
import { SurfaceType } from './surfaceType';
import { WorkCategories } from './workCategories';
type OptionType = {
  label: string;
  value: string;
};

const SummariesTables = () => {
  const [selectedTables, setSelectedTables] = useState<OptionType[]>([]);

  const dissortbyMulti = [
    {
      label: 'SELECT SUMMARY',
      options: [
        { label: 'Per Agency', value: 'PerAgency' },
        { label: 'Per County', value: 'PerCounty' },
        { label: 'County', value: 'County' },
        { label: 'Surface Type', value: 'SurfaceType' },
        { label: 'Work Categories', value: 'WorkCategories' },
      ],
    },
  ];

  // Create a mapping between table values and components
  const tableComponentMapping: Record<string, React.ReactNode> = {
    PerAgency: <PerAgency />,
    PerCounty: <PerCountyRegion />,
    County: <CountyRegion />,
    SurfaceType: <SurfaceType />,
    WorkCategories: <WorkCategories />,
  };

  // Function to render the selected table component
  const renderSelectedTableComponent = () => {
    if (selectedTables.length === 0) {
      // No table selected, render all tables
      return (
        <>
          {Object.values(tableComponentMapping)}
        </>
      );
    } else {
      // Render selected table components
      return selectedTables.map((table: any) => (
        <div className="table-wrapper" key={table.value}>
          <h2>{table.label}</h2>
          {tableComponentMapping[table.value]}
        </div>
      ));
    }
  };

  return (
    <>
      <Row>
        <Col lg={12}>
          <Card>
            <CardHeader>
              <h4 className="card-title mb-0">Summaries Tables</h4>
            </CardHeader>

            <CardBody>
              <div id="customerList">
                <Row className="g-4 mb-3">
                  <Col className="col-sm">
                  <Select
      value={selectedTables}
      isMulti
      onChange={(selectedOptions) => {
        setSelectedTables([...selectedOptions || []]); // Create a copy of selectedOptions
      }}
      options={dissortbyMulti[0].options}
      classNamePrefix="js-example-disabled-multi mb-0"
    />
                  </Col>
                  {/* <Col className="col-sm">
                    <div className="d-flex justify-content-sm-end">
                      <div className="search-box ms-2">
                        <input
                          type="text"
                          className="form-control search"
                          placeholder="Search..."
                        />
                        <i className="ri-search-line search-icon"></i>
                      </div>
                    </div>
                  </Col> */}
                </Row>

                <div className="table-responsive table-card mt-3 mb-1">
                  <div className="table align-middle table-nowrap">
                    {renderSelectedTableComponent()}
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default SummariesTables;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ARWPFilesTable from './ARWPFilesTable'
import { FILESCOLUMNS } from './Constants'

const ARWPListTable = () => {
    const [loading, setLoading] = useState(false);
    const [dataSource, setDataSource] = useState<File[]>([]);

  async function fetchData() {
    try {
      setLoading(true);
      const response = await axios.get('http://194.163.134.15:8007/api/v2/arwp/uploads');
      const dataArray = response.data || []; // Check if data is defined
      setDataSource(dataArray);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData(); // Fetch data when the component mounts
  }, []);
  return (
    <ARWPFilesTable parentColumns={FILESCOLUMNS}  dataSource={dataSource}/>
  )
}

export default ARWPListTable
/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import axios, { AxiosResponse } from 'axios';
//Import Breadcrumb
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { USERSCOLUMNS } from './Contants'
import UsersTable from './UsersTable';

interface usersListViewModels {
  id: string;
  user_name: string;
  email_address: string;
  email_confirmed: boolean;
  mobile_no: string;
  phone_confirmed: boolean;
  directorate: string;
  status: string;
  isInAdministratorRole: boolean;
}

// interface ApiResponse {
//   usersListViewModels: usersListViewModels[];
//   // Other properties from the response if any
// }


const Users = () => {
  document.title = 'Users | APRPMS';

    const [modalList, setModalList] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [refreshTable, setRefreshTable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  function toggleList() {
    setModalList(!modalList);
  }

  // function toggleDelete() {
  //   setModalDelete(!modalDelete);
  // }
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState<any>(null);
  const [count, setCount] = useState<number>(0);

  useEffect(() => {
    fetchData();
  }, [refreshTable]);

  const toggleRefresh = () => {
    setRefreshTable(true); // Step 2: Update the state variable to trigger a refresh
  };

  async function fetchData() {
    try {
      setIsLoading(true);
      const response = await axios.post(
        'http://194.163.134.15:8003/api/ApplicationUsers/GetAllUsersAsync2',
        {
          pageNumber: 1,
          pageSize: 100,
          orderBy: 'asc',
          sortColumnReal: '',
          searchValue: '',
        }
      );
      const dataArray = response.data.usersListPagedViewModel.usersListViewModels;
      // console.log(response.data);
      setData(dataArray);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(error);
    } finally {
      setIsLoading(false); // Step 3: Set loading state back to false after data is fetched
    }
  }

  const [filterTable, setFilterTable] = useState<any>(null);
  const [currentDataSource, setCurrentDataSource] = useState<any>(data);

  useEffect(() => {
    setCurrentDataSource(data);
  }, [data]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb
            title="Users"
            breadcrumbItem="Users"
          />
             <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">Users</h4>
                </CardHeader>
                <CardBody>
                  <div id="customerList">
                    <Row className="g-4">
                      <Col className="col-sm-auto">
                        <div>
                          <Button
                            color="success"
                            className="add-btn me-1"
                            onClick={() => toggleList()}
                            id="create-btn"
                          >
                            <i className="ri-add-line align-bottom me-1"></i> Add
                          </Button>
                          <Button
                            color="primary"
                            className="add-btn me-1"
                            onClick={toggleRefresh}
                            id="create-btn"
                          >
                            <i className="ri-refresh-line align-bottom me-1"></i> Sync
                          </Button>
                        </div>
                      </Col>
                      <Col className="col-sm">
                        <div className="d-flex justify-content-sm-end">
                          <div className="search-box ms-2">
                            <input
                              type="text"
                              className="form-control search"
                              placeholder="Search..."
                            />
                            <i className="ri-search-line search-icon"></i>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <div className="table-responsive table-card mb-1">
                      <div className="table align-middle table-nowrap">
                        <UsersTable columns={USERSCOLUMNS} dataSource={currentDataSource || []} />
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Users;

import React, { useState, useEffect } from 'react';
import {
  Card,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  // @ts-ignore
  FormFeedback,
  // @ts-ignore
  Alert,
  Spinner,
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
// @ts-ignore
import { loginUser, socialLogin, resetLoginFlag } from '../../store/actions';
// @ts-ignore
import logoLight from '../../assets/images/logo-light.png';
import { createSelector } from 'reselect';
import AuthSlider from '../AuthenticationInner/authCarousel';
import axios from 'axios'; // Import Axios or your preferred HTTP library
import { loginSuccess } from '../../store/actions';
import { useNavigate } from 'react-router-dom';
import { UncontrolledAlert } from 'reactstrap'; 

// @ts-ignore
const Login = (props) => {
  const dispatch = useDispatch();
  // const history = useHistory();
  const navigate = useNavigate();

  // @ts-ignore
  const [user, setUser] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  // @ts-ignore
  const [requiredAlert, setRequiredAlert] = useState(false)
  // @ts-ignore
  const selectLayoutState = (state) => state.Account;
  const selectLayoutProperties = createSelector(
    selectLayoutState,
    (layout) => ({
      user: layout.user,
      errorMsg: layout.errorMsg,
      loading: layout.loading,
      error: layout.error,
    })
  );

  // @ts-ignore
  const { errorMsg, loading, error } = useSelector(selectLayoutProperties);

  // @ts-ignore
  const [userLogin, setUserLogin] = useState([]);
  const [passwordShow, setPasswordShow] = useState(false);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        dispatch(resetLoginFlag());
      }, 3000);
    }
  }, [dispatch, error]);

  // @ts-ignore
  const validationSchema = Yup.object({
    username: Yup.string().required('Please enter your username'),
    password: Yup.string().required('Please enter your password'),
  });

  // @ts-ignore
  const handleFormSubmit = async (e) => {
    // Make the function asynchronous
    e.preventDefault();

    const formData = new FormData(e.target);
    const username = formData.get('username');
    const password = formData.get('password');

    if (!username || !password) {
      // Handle the case when the username or password is not provided
      if (!username) {
       
        // Display an error message or perform some validation logic for the username field
        console.log('Username is required');
      }
      if (!password) {
   
        // Display an error message or perform some validation logic for the password field
        console.log('Password is required');
      }
      // Return early to prevent further execution
      return;
    }
    try {
      const response = await axios.post(
        'http://194.163.134.15:8003/api/Auth/Login',
        {
          username,
          password,
        }
      );
      if (response.status === 200) {
        // Update the code to handle the successful response
        const responseData = response.data;
        dispatch(loginSuccess(responseData));
        console.log(responseData);
        console.log(
          'Login successful. Please check your mobile/email for the OTP.'
        );
        // You can also display the additional information from the response
        const { email, id, otp, subject, phoneNumber } = responseData;
        console.log(`OTP Code: ${otp} | Subject: ${subject}`);
        sessionStorage.setItem('id', id);
        sessionStorage.setItem('code', otp);
        sessionStorage.setItem('email', email);
        sessionStorage.setItem('phoneNumber', phoneNumber);
        // Add the redirect to '/auth-lockscreen-cover'
        // Redirect to '/auth-lockscreen-cover'
        navigate('/auth-lockscreen-cover');
      } else {
        setShowAlert(true); 
        // Handle the case when the API returns an error status
        console.error('Login failed:', response.status);
        // You can also dispatch an error action or display an error message to the user
      }
    } catch (error) {
      setShowAlert(true); 
      // Handle any network or request error
      console.error('Network or request error:', error);
      // You can also dispatch an error action or display an error message to the user
    }
  };

  useEffect(() => {
    if (user && user) {
      // @ts-ignore
      const updatedUserData = user.user.username; // Changed 'email' to 'username'
      setUserLogin({
        // @ts-ignore
        username: updatedUserData, // Changed 'email' to 'username'
        // @ts-ignore
        password: user.user.confirm_password ? user.user.confirm_password : '',
      });
    }
  }, [user]);

  document.title = 'SignIn | APRP';

  return (
    <React.Fragment>
      <div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
        <div className="bg-overlay"></div>
        <div className="auth-page-content overflow-hidden pt-lg-5 border-primary">
          <Container>
            <Row>
              <Col lg={12}>
                <Card className="overflow-hidden">
                  <Row className="g-0 auth-page-form">
                    <AuthSlider />

                    <Col lg={6}>
                      <div className=" p-lg-5 p-4">
                        <div>
                          <h5 className="text-primary">WELCOME !</h5>
                          <p className="text-muted">Sign In to Continue</p>
                        </div>

                        <div className="p-2 mt-4">
                          {/* Conditional rendering of Danger Alert */}

                          {showAlert && ( // Display the alert when showAlert is true
                            <UncontrolledAlert color="danger" className="alert-label-icon rounded-label">
                              <i className="ri-error-warning-line label-icon"></i><strong>Warning</strong> - Please provide valid credentials
                            </UncontrolledAlert>
                          )}

                          <form onSubmit={handleFormSubmit}>
                            <div className="mb-3">
                              <Label
                                htmlFor="username"
                                className="form-label">
                               
                                Username 
                              </Label>
                              <Input
                                name="username" 
                                className="form-control"
                                placeholder="Enter username"
                                type="text"
                                required
                              />
                            </div>

                            <div className="mb-3">
                              <div className="float-end">
                                <Link
                                  to="/forgot-password"
                                  className="text-muted">
                                  Forgot password?
                                </Link>
                              </div>
                              <Label
                                className="form-label"
                                htmlFor="password-input">
                                Password
                              </Label>
                              <div className="position-relative auth-pass-inputgroup mb-3">
                                <Input
                                  name="password"
                                  placeholder="Enter Password"
                                  type={passwordShow ? 'text' : 'password'}
                                  required
                                />
                                <button
                                  className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                                  type="button"
                                  onClick={() =>
                                    setPasswordShow(!passwordShow)
                                  }>
                                  <i className="ri-eye-fill align-middle"></i>
                                </button>
                              </div>
                            </div>

                            <div className="form-check">
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="auth-remember-check"
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="auth-remember-check">
                                Remember me
                              </Label>
                            </div>

                            <div className="mt-4">
                              <Button
                                color="secondary"
                                // @ts-ignore
                                disabled={error ? null : loading ? true : false}
                                className="btn btn-primary w-100"
                                type="submit">
                                {error ? null : loading ? (
                                  <Spinner
                                    size="sm"
                                    className="me-2">
                                    Loading...
                                  </Spinner>
                                ) : null}
                                Sign In
                              </Button>
                            </div>
                          </form>
                        </div>

                        <div className="mt-4 text-center">
                          <p className="mb-0">
                            Don't have an account ?{' '}
                            <Link
                              to="/register"
                              className="fw-semibold text-primary text-decoration-underline">
                              Signup
                            </Link>
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Login;

import React from 'react';
import { Space, Table, Tag, Dropdown, Menu } from 'antd';


interface Person {
  id: string; // Assuming you have an ID property
  firstName: string;
  middleName: string;
  lastName: string;
  emailAddress: string;
  mobileNo: number;
  designation: string;
  directorate: {
    directorateName: string; // Rename the property
  };
  emailConfirmed: boolean; // Add this line
}

export const ACCESSLISTCOLUMNS = [
  // {
  //   title: 'id',
  //   key: 'id',
  //   dataIndex: 'id',
  // },
  {
    title: 'First Name',
    key: 'firstName',
    dataIndex: 'firstName',
    sorter: (a: Person, b: Person) => a.firstName.localeCompare(b.firstName),
  },
  {
    title: 'Middle Name',
    key: 'middleName',
    dataIndex: 'middleName',
    sorter: (a: Person, b: Person) => a.middleName.localeCompare(b.middleName),
  },
  {
    title: 'Last Name',
    key: 'lastName',
    dataIndex: 'lastName',
    sorter: (a: Person, b: Person) => a.lastName.localeCompare(b.lastName),
  },
  {
    title: 'Email Address',
    key: 'emailAddress',
    dataIndex: 'emailAddress',
    sorter: (a: Person, b: Person) =>
      a.emailAddress.localeCompare(b.emailAddress),
  },
  {
    title: 'Mobile No.',
    key: 'mobileNo',
    dataIndex: 'mobileNo',
    sorter: (a: Person, b: Person) => a.mobileNo - b.mobileNo,
  },
  {
    title: 'Designation',
    key: 'designation',
    dataIndex: 'designation',
    sorter: (a: Person, b: Person) => {
      const designationA = a.designation || ''; // Default to an empty string if null
      const designationB = b.designation || ''; // Default to an empty string if null
      return designationA.localeCompare(designationB);
    },
    
  },
  {
    title: 'Directorate',
    dataIndex: ['directorate', 'name'], // Access the nested property correctly
    key: 'directorateName',
    sorter: (a: Person, b: Person) => {
      const nameA = a.directorate?.directorateName || ''; // Null or undefined check
      const nameB = b.directorate?.directorateName || ''; // Null or undefined check
      console.log('nameA:', nameA);
  console.log('nameB:', nameB);
      return nameA.localeCompare(nameB);
    },
    onFilter: (value: string, record: Person) => record.directorate.directorateName === value,
    
  },
  {
    title: 'Email Status',
    key: 'emailConfirmed',
    dataIndex: 'emailConfirmed',
    sorter: (a: Person, b: Person) => {
      return a.emailConfirmed === b.emailConfirmed
        ? 0
        : a.emailConfirmed
        ? 1
        : -1;
    },

    render: (emailConfirmed: boolean) => (
      <Tag color={emailConfirmed ? 'green' : 'red'}>
        {emailConfirmed ? 'Verified' : 'Unverified'}
      </Tag>
    ),
  },
   // Actions Column
  // Actions Column
  {
    title: 'Actions',
    key: 'actions',
    render: (text: string, record: Person) => (
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item key="edit">
            <div className="dropdown-menu-item">
              <a href={`http://194.163.134.15:8003/api/UserManagement/AccessListEdit/${record.id}`} target="_blank" rel="noopener noreferrer">
                Edit
              </a>
              <i color='' className="ri-edit-2-line"></i>
              </div>
            </Menu.Item>
            <Menu.Item key="remove">
            <div className="dropdown-menu-item">
              <a href={`http://194.163.134.15:8003/api/UserManagement/AccessListDelete/${record.id}`} target="_blank" rel="noopener noreferrer">
                Remove
              </a>
              <i className="ri-delete-bin-line"></i></div>
            </Menu.Item>
            {record.emailConfirmed ? (
              <Menu.Item key="invalidateEmail">
                 <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <a href={`http://example.com/your-invalidate-email-endpoint/${record.id}`} target="_blank" rel="noopener noreferrer">
                  Invalidate Email
                </a>
                <i className="ri-mail-close-line"></i>
                 </div>
              </Menu.Item>
            ) : (
              <Menu.Item key="validateEmail">
                 <div className="dropdown-menu-item">
                  <a href={`http://example.com/your-validate-email-endpoint/${record.id}`} target="_blank" rel="noopener noreferrer">
                    Validate Email
                  </a>
                  <i className="ri-mail-check-line"></i>
                </div>
              </Menu.Item>
            )}
          </Menu>
        }
       
      >
        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
              <circle cx="8" cy="4" r="1" />
              <circle cx="8" cy="8" r="1" />
              <circle cx="8" cy="12" r="1" />
        </svg>
        </a>
      </Dropdown>
    ),
  },
  // {
  //   title: 'Actions',
  //   key: 'Actions',
  //   dataIndex: 'Actions',
  // },
];

import React, { useState } from 'react';
import { Card, CardHeader, Col, Input, Row } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const FileUpload = () => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event: any) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append('uploadFile', selectedFile); // Use the correct field name
  
      fetch('http://194.163.134.15:8007/api/v2/arwp/upload', {
        method: 'POST',
        body: formData,
      })
        .then((response) => {
          if (response.ok) {
            console.log('File uploaded successfully');
            // Show success toast
            toast.success('File uploaded successfully', {
              position: 'top-right',
            });
  
            // Clear the selected file after successful upload
            setSelectedFile(null);
  
            // Clear the file input field by setting its value to an empty string
            const fileInput = document.getElementById('inputGroupFile04') as HTMLInputElement;
            if (fileInput) {
              fileInput.value = '';
            }
  
            // Handle success here
          } else {
            console.error('File upload failed with status code:', response.status);
            response.json().then((errorData) => {
              console.error('Error message:', errorData);
              // Show error toast
              toast.error('File upload failed', {
                position: 'top-right',
              });
              // Handle the error here, display an error message, etc.
            });
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    } else {
      console.warn('No file selected for upload');
      // Show a warning toast
      toast.warning('No file selected for upload', {
        position: 'top-right',
      });
    }
  };
  
  

  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardHeader>
            <h4 className="card-title mb-0">Upload ARWP Data</h4>
          </CardHeader>
          <div className="card-body">
            <div className="live-preview">
              <div className="mb-4">
                <h5 className="fs-14 mb-3">Browse and Upload ARWP File</h5>
                <Row className="g-3">
                  <Col lg={12}>
                    <div className="input-group">
                      <Input
                        type="file"
                        className="form-control"
                        id="inputGroupFile04"
                        aria-describedby="inputGroupFileAddon04"
                        aria-label="Upload"
                        onChange={handleFileChange}
                      />
                      <button
                        className="btn btn-primary"
                        type="button"
                        id="inputGroupFileAddon04"
                        onClick={handleUpload}
                      >
                        SUBMIT
                      </button>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <p className="text-muted">
              {/* Download link for the template with MDI icon and CSS styles */}
              <a
                href="/template.csv" // Update this path to match the actual location of your CSV file
                download="template.csv"
                style={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  textDecoration: 'none',
                  cursor: 'pointer', // Hover pointer cursor
                }}
              >
                 <i className="ri-download-2-line" style={{ marginRight: '5px' }} ></i> {/* MDI icon */}
                Download the ARWP data Upload template here.
              </a>
            </p>
          </div>
        </Card>
      </Col>
      <ToastContainer /> {/* Render the ToastContainer */}
    </Row>
  );
};

export default FileUpload;


import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalFooter, ModalHeader,Button } from 'reactstrap';
import { ConfirmLogout } from '../../views';
//import images
import { Link, useNavigate } from 'react-router-dom';
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import { createSelector } from 'reselect';
import axios from 'axios';
import { Avatar } from 'antd';

const UserProfile = ({ username, avatarUrl }) => {
  // Determine if an avatar image is available
  const hasAvatar = !!avatarUrl;

  // Function to get initials from username
  const getInitials = (name) => {
    const nameArray = name.split(' ');
    return nameArray.map((part) => part[0]).join('').toUpperCase();
  };

  return (
    <Avatar
      src={hasAvatar ? avatarUrl : null} // Set src to null if no avatar image available
      size={64}
    >
      {hasAvatar ? null : getInitials(username)}
    </Avatar>
  );
};

const ProfileDropdown = () => {
  const navigate = useNavigate();

  // Function to log out the user
  const logoutUser = () => {
    // Clear user session data, including the token
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('id');
    sessionStorage.removeItem('code');
    sessionStorage.removeItem('email');
    sessionStorage.removeItem('phoneNumber');
  
    // Redirect the user to the login page (you can replace '/login' with your actual login page URL)
    navigate('/login');
  };






    const [modalList, setModalList] = useState(false);
    const [modalDelete, setModalDelete] = useState(false);
  
    function toggleList() {
      setModalList(!modalList);
    }
  
    function toggleDelete() {
      setModalDelete(!modalDelete);
    }
    const [userData, setUserData] = useState({});
    const [loading, setLoading] = useState(true);
    useEffect(() => {
      // Retrieve the token from sessionStorage
      const token = sessionStorage.getItem('token');
  
      // Check if the token exists
      if (token) {
        // Include the token in the request headers
        axios
          .get('http://194.163.134.15:8003/api/Auth/GetUserFromToken', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            setUserData(response.data.applicationUser);
            setLoading(false);
          })
          .catch((error) => {
            console.error('Error fetching user data:', error);
            setLoading(false);
          });
      } else {
        // Handle the case where the token is not found in sessionStorage
        console.error('Token not found in sessionStorage');
        setLoading(false);
      }
    }, []);

    const selectDashboardData = createSelector(
        (state) => state.Profile.user,
        (user) => user
      );
      
    // Inside your component
    const user = useSelector(selectDashboardData);


    const [userName, setUserName] = useState("Admin");


    useEffect(() => {
        if (sessionStorage.getItem("authUser")) {
            const obj = JSON.parse(sessionStorage.getItem("authUser"));
            setUserName(process.env.REACT_APP_DEFAULTAUTH === "fake" ? obj.username === undefined ? user.first_name ? user.first_name : obj.data.first_name : "Admin" || "Admin" :
                process.env.REACT_APP_DEFAULTAUTH === "firebase" ? obj.providerData[0].email : "Admin"
            );
        }
    }, [userName, user]);

    //Dropdown Toggle
    const [isProfileDropdown, setIsProfileDropdown] = useState(false);
    const toggleProfileDropdown = () => {
        setIsProfileDropdown(!isProfileDropdown);
    };
    return (
        <React.Fragment>
            <Dropdown isOpen={isProfileDropdown} toggle={toggleProfileDropdown} className="ms-sm-3 header-item topbar-user">
                <DropdownToggle tag="button" type="button" className="btn shadow-none">
                    <span className="d-flex align-items-center">
                    <UserProfile username={userName} avatarUrl="" />

                        <span className="text-start ms-xl-2">
                            <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{userName}</span>
                            <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">Admin</span>
                        </span>
                    </span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">

                    <h6 className="dropdown-header">Welcome {userName}!</h6>
                    <DropdownItem href={process.env.PUBLIC_URL + "/pages-profile-settings"}><i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
                        <span className="align-middle">Profile</span></DropdownItem>
                    {/* <DropdownItem href={process.env.PUBLIC_URL + "/apps-chat"}><i
                        className="mdi mdi-message-text-outline text-muted fs-16 align-middle me-1"></i> <span
                            className="align-middle">Messages</span></DropdownItem>
                    <DropdownItem href={process.env.PUBLIC_URL + "#"}><i
                        className="mdi mdi-calendar-check-outline text-muted fs-16 align-middle me-1"></i> <span
                            className="align-middle">Taskboard</span></DropdownItem> */}
                    {/* <DropdownItem href={process.env.PUBLIC_URL + "/pages-faqs"}><span
                        className="badge bg-success-subtle text-success mt-1 float-end">New</span><i
                        className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i> <span
                            className="align-middle">Help</span></DropdownItem> */}
                    <div className="dropdown-divider"></div>
                   
                    {/* <DropdownItem href={process.env.PUBLIC_URL + "/pages-profile-settings"}><i
                            className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i> <span
                                className="align-middle">Settings</span></DropdownItem> */}
                    {/* <DropdownItem href={process.env.PUBLIC_URL + "/auth-lockscreen-basic"}><i
                        className="mdi mdi-lock text-muted fs-16 align-middle me-1"></i> <span className="align-middle">Lock screen</span></DropdownItem> */}
                    <DropdownItem color="success"

                        onClick={() => toggleList()}
                        id="create-btn"><i
                        className="mdi mdi-logout text-muted fs-16 align-middle me-1"  ></i> <span
                            className="align-middle" data-key="t-logout">Logout</span>
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
            <Modal  
            isOpen={modalList}
        toggle={() => {
          toggleList();
        }}
        centered>
            <ModalHeader
          className="bg-light p-3"
          toggle={() => {
            toggleList();
          }}
        >
          <span className="mdi mdi-logout"></span>
          Confirm Logout
        </ModalHeader>
        <ModalBody>
       
                   
                     
                      <div className="">
                        <div className="p-4">
                          <div className="text-center mt-2">
                          <div className="text">
                                <h5 className="text-primary">  ARE YOU SURE YOU WANT TO LOGOUT?</h5
                                ></div>
                          </div>
                          <div className="user-thumb text-center mt-4">
                          <UserProfile username={userName} avatarUrl="" />
                            <p className="mt-2">{userName}</p>
                          </div>   
                          
                      
                          <div className="p-2 mt-3">
                            <form>
                              {/* <div className="mb-3">
                                <label
                                  className="form-label"
                                  htmlFor="userpassword">
                                  Password
                                </label>
                                <input
                                  type="password"
                                  className="form-control"
                                  id="userpassword"
                                  placeholder="Enter password"
                                  required
                                />
                              </div> */}
                              <div className="mb-2 mt-4">
                                <Button
                                  color="success"
                                  className="w-100"
                                  onClick={logoutUser}>
                                 Logout
                                </Button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      
                   
                
        </ModalBody>
        {/* <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setModalList(false)}
              >
                Cancel
              </button>
            </div>
          </ModalFooter> */}
            </Modal>
        </React.Fragment>
    );
};

export default ProfileDropdown;
/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { Link } from "react-router-dom";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import AccessListTable from "./AccessListTable";
import { ACCESSLISTCOLUMNS } from "./Constants";
import axios from "axios";

interface Directorate {
  name: string;
  directorateId: number;
}

interface Department {
  departmentId: number;
  name: string;
  // other properties
}

const UserAccessList: React.FC = () => {
  document.title = "User Access List | APRPMS";

  const [modalList, setModalList] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);

  function toggleList() {
    setModalList(!modalList);
  }

  function toggleDelete() {
    setModalDelete(!modalDelete);
  }

  const [data, setData] = useState<any>(null);
  const [error, setError] = useState<any>(null);
  const [count, setCount] = useState<number>(0);

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    try {
      const response = await axios.get(
        `http://194.163.134.15:8003/api/UserManagement/UserAccessList`
      );
      const dataArray = response.data.accessList;
      const dataCount = dataArray.length;
      setData(dataArray);
      setCount(dataCount);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const [filterTable, setFilterTable] = useState<any>(null);
  const [currentDataSource, setCurrentDataSource] = useState<any>(data);

  useEffect(() => {
    setCurrentDataSource(data);
  }, [data]);

  const [directorates, setDirectorates] = useState<Directorate[]>([]);
  const [selectedDirectorate, setSelectedDirectorate] = useState<string>("");
  const [departments, setDepartments] = useState<Department[]>([]);
  const [selectedDepartment, setSelectedDepartment] = useState<string>("");
  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = async () => {
    try {
      const response = await axios.get(
        "http://194.163.134.15:8003/api/Authority/ListDirectoriesAsync"
      );
      setDirectorates(response.data.directorates);
      console.log(response);
    } catch (error) {
      console.error("Error fetching directorates:", error);
    }
  };

  const handleDirectorateChange = async (e: any) => {
    const directorateId = e.target.value;
    setSelectedDirectorate(directorateId);
    if (directorateId) {
      const fetchedDepartments = await fetchDepartments(directorateId);
      setDepartments(fetchedDepartments);
    } else {
      setDepartments([]); // Clear departments when "Select Directorate" is chosen
    }
  };

  useEffect(() => {
    if (selectedDirectorate) {
      fetchDepartments(selectedDirectorate)
        .then((fetchedDepartments) => {
          setDepartments(fetchedDepartments);
        })
        .catch((error) => {
          console.error("Error fetching departments:", error);
        });
    } else {
      setDepartments([]); // Clear departments when "Select Directorate" is chosen
    }
  }, [selectedDirectorate]); // Include selectedDirectorate in the dependency array

  const fetchDepartments = async (directorate: any) => {
    try {
      const url = `http://194.163.134.15:8003/api/Authority/FindDirectorateByIdAsync/${directorate}`;
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(`Network response was not ok for URL: ${url}`);
      }

      const data = await response.json();

      if (data.directorate && data.directorate.departments) {
        return data.directorate.departments;
      } else {
        throw new Error(
          `Invalid data format received from API for URL: ${url}`
        );
      }
    } catch (error) {
      console.error("Error fetching departments:", error);
      return [];
    }
  };

  const initialFormData = {
    DirectorateId: "",
    DepartmentId: "",
    FirstName: "",
    MiddleName: "",
    LastName: "",
    EmailAddress: "",
    NationalID: "",
    MobileNo: "",
    Designation: "",
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "http://194.163.134.15:8003/api/UserManagement/AccessListAdd",
        formData
      );

      if (response.status === 200) {
        console.log("User added successfully");
        setFormData(initialFormData);
      } else {
        console.error("Error adding user:", response.data);
      }
    } catch (error) {
      console.error("Error adding user:", error);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb
            title="User Access List"
            breadcrumbItem="User Access List"
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">User Access List</h4>
                </CardHeader>
                <CardBody>
                  <div id="customerList">
                    <Row className="g-4">
                      <Col className="col-sm-auto">
                        <div>
                          <Button
                            color="success"
                            className="add-btn me-1"
                            onClick={() => toggleList()}
                            id="create-btn"
                          >
                            <i className="ri-add-line align-bottom me-1"></i>{" "}
                            Add
                          </Button>
                          <Button
                            color="primary"
                            className="add-btn me-1"
                            onClick={() => toggleList()}
                            id="create-btn"
                          >
                            <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                            Sync
                          </Button>
                        </div>
                      </Col>
                      <Col className="col-sm">
                        <div className="d-flex justify-content-sm-end">
                          <div className="search-box ms-2">
                            <input
                              type="text"
                              className="form-control search"
                              placeholder="Search..."
                            />
                            <i className="ri-search-line search-icon"></i>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <div className="table-responsive table-card mb-1">
                      <div className="table align-middle table-nowrap">
                        <AccessListTable
                          columns={ACCESSLISTCOLUMNS}
                          dataSource={currentDataSource || []}
                        />
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal
        isOpen={modalList}
        toggle={() => {
          toggleList();
        }}
        centered
      >
        <ModalHeader
          className="bg-light p-3"
          toggle={() => {
            toggleList();
          }}
        >
          Add User to Access List
        </ModalHeader>
        <form className="tablelist-form" onSubmit={handleSubmit}>
          <ModalBody>
            <div className="mb-3">
              <label htmlFor="directoratename-field" className="form-label">
                Directorate
              </label>
              <select
                className="form-control"
                id="directoratename-field"
                name="directoratename"
                value={selectedDirectorate}
                onChange={handleDirectorateChange}
                required
              >
                <option value="">Select Directorate</option>
                {directorates &&
                  directorates.map((dir) => (
                    <option key={dir.directorateId} value={dir.name}>
                      {dir.name}
                    </option>
                  ))}
              </select>
            </div>

            <div className="mb-3">
              <label htmlFor="department-field" className="form-label">
                Department
              </label>
              <select
                className="form-control"
                id="department-field"
                name="department"
                value={selectedDepartment}
                onChange={(e) => setSelectedDepartment(e.target.value)}
                required
              >
                <option value="">Select Department</option>
                {departments &&
                  departments.map((dept) => (
                    <option key={dept.departmentId} value={dept.name}>
                      {dept.name}
                    </option>
                  ))}
              </select>
            </div>

            <div className="mb-3">
              <label htmlFor="firstname-field" className="form-label">
                First Name
              </label>
              <input
                type="text"
                id="firstname-field"
                className="form-control"
                placeholder="Enter First Name"
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="middlename-field" className="form-label">
                Middle Name
              </label>
              <input
                type="text"
                id="middlename-field"
                className="form-control"
                placeholder="Enter Middle Name"
              />
            </div>

            <div className="mb-3">
              <label htmlFor="lastname-field" className="form-label">
                Last Name
              </label>
              <input
                type="text"
                id="lastname-field"
                className="form-control"
                placeholder="Enter Last Name"
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="email-field" className="form-label">
                Email Address
              </label>
              <input
                type="email"
                id="email-field"
                className="form-control"
                placeholder="Enter Email"
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="pno-field" className="form-label">
                Personal Number (P/No)
              </label>
              <input
                type="text"
                id="pno-field"
                className="form-control"
                placeholder="Enter Personal Number (P/No)"
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="mobile-field" className="form-label">
                Mobile No
              </label>
              <input
                type="text"
                id="mobile-field"
                className="form-control"
                placeholder="Enter Mobile No"
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="designation-field" className="form-label">
                Designation
              </label>
              <input
                type="text"
                id="designation-field"
                className="form-control"
                placeholder="Enter Designation"
                required
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setModalList(false)}
              >
                Close
              </button>
              <button type="submit" className="btn btn-success" id="add-btn">
                Add User
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>
    </React.Fragment>
  );
};

export default UserAccessList;
/* eslint-enable no-restricted-globals */

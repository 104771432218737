// import moment from 'moment';
import React from 'react';
import Table from '../../../Components/Tables/Table';
// import { useSelector } from 'react-redux';

interface Props {
  columns: any[];
  dataSource: any[];
}

const UsersTable: React.FC<Props> = (props) => {
  const { columns, dataSource } = props;
  // const csvName = dataSource.map((item) => item.vehicle)[0];


  const formatData = (data: any) =>
    data.map((item: any, index: number) => {
    //   const { paid, startDate, vehicle, endDate, hasPenalty, timestamp, zone, username, vehicleType } = item;
      return {
        ...item,
        key: index,
      };
    });

  const allColumns = [...columns];
  return (
    <Table
      // showDownload
    //   loading={secondaryLoading}
      dataSource={formatData(dataSource.sort((a, b) => b.id - a.id))}
      columns={allColumns}
      // csvName={csvName + ' ' + 'Statement'}
    />
  );
};

export default UsersTable;
// @ts-nocheck
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Card, Col, Container, Row } from 'reactstrap';
import axios from 'axios';

//import images
import avatar1 from '../../../assets/images/users/avatar-1.jpg';

import AuthSlider from '../authCarousel';

const CoverLockScreen = () => {
  const [otp, setOtp] = useState('');
  const navigate = useNavigate();

// Function to log out the user
const logoutUser = () => {
  // Clear user session data, including the token
  localStorage.removeItem('token');
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('id');
  sessionStorage.removeItem('code');
  sessionStorage.removeItem('email');
  sessionStorage.removeItem('phoneNumber');

  // Redirect the user to the login page (you can replace '/login' with your actual login page URL)
  navigate('/login');
};

const handleSubmit = async (e) => {
  e.preventDefault();

  try {
    const id = sessionStorage.getItem('id');
    const code = sessionStorage.getItem('code');
    const email = sessionStorage.getItem('email');
    const phoneNumber = sessionStorage.getItem('phoneNumber');

    if (otp === code) {
      const response = await axios.post(
        'http://194.163.134.15:8003/api/Auth/SignInVerification',
        {
          id,
          code,
          email,
          phoneNumber,
          otp,
        }
      );

      if (response.status === 200) {
        const token = response.data.token;
        const expirationDateStr = response.data.expiration; // Get the expiration date from the response
        const expirationDate = new Date(expirationDateStr);
        localStorage.setItem('token', token);
        sessionStorage.setItem('token', token);
        console.log('Token stored in session storage:', sessionStorage.getItem('token'));
        console.log('token expiration time', expirationDate)
        const currentTime = Date.now();
        const timeUntilExpiration = expirationDate - currentTime;

        if (timeUntilExpiration > 0) {
          setTimeout(() => {
            // Log the user out when the token expires
            logoutUser();
          }, timeUntilExpiration);
        }

        navigate('/dashboard');
      } else {
        console.error('OTP verification failed:', response.status);
        // Display an error message to the user indicating that the OTP verification failed
      }
    } else {
      console.error('Invalid OTP');
      // Display an error message to the user indicating that the OTP is invalid
    }
  } catch (error) {
    console.error('Network or request error:', error);
    // Display an error message to the user indicating a network or request error
  }
};


  document.title = 'OTP | APRP';


  return (
    <React.Fragment>
      <div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
        <div className="bg-overlay"></div>
        <div className="auth-page-content overflow-hidden pt-lg-5">
          <Container>
            <Row>
              <Col lg={12}>
                <Card className="overflow-hidden">
                  <Row className="justify-content-center g-0">
                    <AuthSlider />
                    <Col lg={6}>
                      <div className="p-lg-5 p-4">
                        <div>
                          <h5 className="text-primary">OTP Verification</h5>
                          <p className="text-muted">
                            Enter the OTP sent to your mobile/email to unlock the screen!
                          </p>
                        </div>
                        <div className="user-thumb text-center">
                          <img
                            src='https://thumbs.dreamstime.com/b/otp-one-time-password-step-authentication-data-protection-internet-security-concept-otp-one-time-password-step-authentication-data-254434939.jpg'
                            className="rounded-circle img-thumbnail avatar-lg"
                            alt="thumbnail"
                          />
                        </div>

                        <div className="mt-4">
                          <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                              <label className="form-label" htmlFor="otp">
                                OTP
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="otp"
                                placeholder="Enter OTP"
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                                required
                              />
                            </div>
                            {/* <Row>
                              {[1, 2, 3, 4,5,6].map((index) => (
                                <Col className="" key={index}>
                                  <div className="mb-3">
                                    <label
                                      htmlFor={`digit${index}-input`}
                                      className="visually-hidden"
                                    >
                                      Digit {index}
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control form-control-lg bg-light border-light text-center"
                                      maxLength={1}
                                      id={`digit${index}-input`}
                                      // HandleKeyUp as needed
                                    />
                                  </div>
                                </Col>
                              ))}
                            </Row> */}
                            <div className="mb-2 mt-4">
                              <Button
                                color="success"
                                className="w-100"
                                type="submit"
                              >
                                Unlock
                              </Button>
                            </div>
                          </form>
                        </div>

                        <div className="mt-5 text-center">
                          <p className="mb-0">
                            Not you? Return{' '}
                            <Link
                              to="/auth-signin-cover"
                              className="fw-semibold text-primary text-decoration-underline"
                            >
                              Signin
                            </Link>
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CoverLockScreen;

/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ARWPFilesTable from './ARWPFilesTable'; // Import your custom component
import { FILESCOLUMNS } from './Constants';

interface File {
  key: string;
  authority: string;
  files: any[];
}

const columns = [
  {
    title: 'Authority',
    dataIndex: 'authority',
    key: 'authority',
  },
];

const ARWPNestedTable: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState<File[]>([]);

  async function fetchData() {
    try {
      setLoading(true);
      const response = await axios.get('http://194.163.134.15:8007/api/v2/arwp/uploads');
      const dataArray = response.data || []; // Check if data is defined
      setDataSource(dataArray);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData(); // Fetch data when the component mounts
  }, []);

  // Group data by authority
  const groupedData: Record<string, File[]> = {};
  
  // Check if dataSource is defined before iterating
  dataSource?.forEach((item) => {
    if (!groupedData[item.authority]) {
      groupedData[item.authority] = [];
    }
    groupedData[item.authority].push(item);
  });

  // Transform grouped data into an array of objects
  const nestedDataSource = Object.keys(groupedData).map((key) => ({
    key,
    authority: key,
    files: groupedData[key] || [], // Check if files array is defined
  }));

  return (
    <ARWPFilesTable
  parentColumns={columns}
  nestedColumns={FILESCOLUMNS}
  dataSource={nestedDataSource.map((item) => ({
    ...item,
    key: item.authority, // Use a unique identifier as the key
  }))}
  
/>

  );
};

export default ARWPNestedTable;

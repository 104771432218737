import React from 'react';
import { Container } from "reactstrap";

//Import Breadcrumb
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import {FileUpload} from './uploadFile';
import {ARWPFiles} from './listFiles';

    const ARWP = () => {
        document.title = "ARWP | APRPMS";   //for meta title
        return (
            <>
                <div className="page-content">
                    <Container fluid={true}>
                        <BreadCrumb title="ARWP" breadcrumbItem="ARWP" />
                        
                        <FileUpload/>
                        
                        <ARWPFiles/>
                    </Container>
                </div>
            </>
        );
    }

export default ARWP;
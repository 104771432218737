import React from 'react';
import { Space, Table, Tag, Dropdown, Menu } from 'antd';

interface Role {
  id: number;
  role_id: number | null;
  claim_type: string;
  permission: string;
  description: string;
}

export const PERMISSIONSCOLUMNS = [
//   {
//     title: 'ID',
//     key: 'id',
//     dataIndex: 'id',
//     sorter: (a: Role, b: Role) => a.id - b.id, 
//   },
//   {
//     title: 'Role ID',
//     key: 'role_id',
//     dataIndex: 'role_id',
//     sorter: (a: Role, b: Role) => {
//       if (a.role_id === null && b.role_id === null) return 0;
//       if (a.role_id === null) return -1;
//       if (b.role_id === null) return 1;
//       return a.role_id - b.role_id;
//     }, 
//   },
  // {
  //   title: 'Claim Type',
  //   key: 'claim_type',
  //   dataIndex: 'claim_type',
  //   sorter: (a: Role, b: Role) => a.claim_type.localeCompare(b.claim_type), // Sort alphabetically
  // },
  {
    title: 'Permission',
    key: 'permission',
    dataIndex: 'permission',
    sorter: (a: Role, b: Role) => a.permission.localeCompare(b.permission), // Sort alphabetically
  },
  {
    title: 'Description',
    key: 'description',
    dataIndex: 'description',
    sorter: (a: Role, b: Role) => a.description.localeCompare(b.description), // Sort alphabetically
  },
  {
    title: 'Actions',
    key: 'actions',
    render: (text: string, record: Role) => (
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item key="edit">
              <div className="dropdown-menu-item">
                <a href={`http://example.com/edit/${record.id}`} target="_blank" rel="noopener noreferrer">
                  Edit
                </a>
                <i color='' className="ri-edit-2-line"></i>
              </div>
            </Menu.Item>
            <Menu.Item key="remove">
              <div className="dropdown-menu-item">
                <a href={`http://example.com/delete/${record.id}`} target="_blank" rel="noopener noreferrer">
                  Remove
                </a>
                <i className="ri-delete-bin-line"></i>
              </div>
            </Menu.Item>
          </Menu>
        }
      >
        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <circle cx="8" cy="4" r="1" />
            <circle cx="8" cy="8" r="1" />
            <circle cx="8" cy="12" r="1" />
          </svg>
        </a>
      </Dropdown>
    ),
  },
];

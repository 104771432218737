import React from 'react';
import { Tag } from 'antd';

interface Activity {
  id: number;
  activityCode: string;
  activityDescription: string;
  method: string;
  unitMeasure: string;
  plannedQuantity: number;
  plannedRateKsh: number;
  workplanDetailId: number;
  amountKsh: number;
}

export const ACTIVITYCOLUMNS = [
  {
    title: 'Activity Code',
    dataIndex: 'activityCode',
    key: 'activityCode',
  },
  {
    title: 'Activity Description',
    dataIndex: 'activityDescription',
    key: 'activityDescription',
  },
  {
    title: 'Method',
    dataIndex: 'method',
    key: 'method',
  },
  {
    title: 'Unit Measure',
    dataIndex: 'unitMeasure',
    key: 'unitMeasure',
  },
  {
    title: 'Planned Quantity',
    dataIndex: 'plannedQuantity',
    key: 'plannedQuantity',
  },
  {
    title: 'Planned Rate (Ksh)',
    dataIndex: 'plannedRateKsh',
    key: 'plannedRateKsh',
  },
  {
    title: 'Amount (Ksh)',
    dataIndex: 'amountKsh',
    key: 'amountKsh',
  },
];

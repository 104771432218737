interface Summary {
    id: number;
    fileName: string;
    authority: string;
    authorityCode: string;
    financialYear: string;
    fundingSource: string;
    fundType: string;
    revisionStatus: string;
    budget: number;
    fileData: any; // Adjust the type according to your needs
    approvalStatus: number;
    boardApproval: number;
    creationDate: string;
    workplanDetails: any[]; // Adjust the type according to your needs
    approvalDetails: any[]; // Adjust the type according to your needs
  }
  
  export const Columns = [
    {
      title: 'File Name',
      dataIndex: 'fileName',
      key: 'fileName',
      sorter: (a: Summary, b: Summary) => a.fileName.localeCompare(b.fileName),
    },
    {
      title: 'Authority',
      dataIndex: 'authority',
      key: 'authority',
      sorter: (a: Summary, b: Summary) => a.authority.localeCompare(b.authority),
    },
    {
      title: 'Authority Code',
      dataIndex: 'authorityCode',
      key: 'authorityCode',
      sorter: (a: Summary, b: Summary) => a.authorityCode.localeCompare(b.authorityCode),
    },
    {
      title: 'Financial Year',
      dataIndex: 'financialYear',
      key: 'financialYear',
      sorter: (a: Summary, b: Summary) => a.financialYear.localeCompare(b.financialYear),
    },
    {
      title: 'Funding Source',
      dataIndex: 'fundingSource',
      key: 'fundingSource',
      sorter: (a: Summary, b: Summary) => a.fundingSource.localeCompare(b.fundingSource),
    },
    {
      title: 'Fund Type',
      dataIndex: 'fundType',
      key: 'fundType',
      sorter: (a: Summary, b: Summary) => a.fundType.localeCompare(b.fundType),
    },
    {
      title: 'Revision Status',
      dataIndex: 'revisionStatus',
      key: 'revisionStatus',
      sorter: (a: Summary, b: Summary) => a.revisionStatus.localeCompare(b.revisionStatus),
    },
    {
      title: 'Budget',
      dataIndex: 'budget',
      key: 'budget',
      sorter: (a: Summary, b: Summary) => a.budget - b.budget,
    },
    {
      title: 'Approval Status',
      dataIndex: 'approvalStatus',
      key: 'approvalStatus',
      sorter: (a: Summary, b: Summary) => a.approvalStatus - b.approvalStatus,
    },
    {
      title: 'Board Approval',
      dataIndex: 'boardApproval',
      key: 'boardApproval',
      sorter: (a: Summary, b: Summary) => a.boardApproval - b.boardApproval,
    },
    {
      title: 'Creation Date',
      dataIndex: 'creationDate',
      key: 'creationDate',
      sorter: (a: Summary, b: Summary) => a.creationDate.localeCompare(b.creationDate),
    },
  ];
  
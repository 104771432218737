import React from "react";
import { Space, Table, Tag, Dropdown, Menu } from "antd";

interface Person {
  name: string;
  description: string;
  fuel_levy_Amount: string;
  transit_Tolls_Amount: string;
  savings: number;
  total_Funds_Available: string;
}

export const BUDGETCEILINGLISTCOLUMNS = [
  {
    title: "Name",
    key: "name",
    dataIndex: "name",
    sorter: (a: Person, b: Person) => a.name.localeCompare(b.name),
  },
  {
    title: "Description",
    key: "description",
    dataIndex: "description",
    sorter: (a: Person, b: Person) =>
      a.description.localeCompare(b.description),
  },
  {
    title: "Fuel Levy",
    key: "fuel_levy_Amount",
    dataIndex: "fuel_levy_Amount",
    sorter: (a: Person, b: Person) =>
      a.fuel_levy_Amount.localeCompare(b.fuel_levy_Amount),
  },
  {
    title: "Transit Tolls Amount",
    key: "transit_Tolls_Amount",
    dataIndex: "transit_Tolls_Amount",
    sorter: (a: Person, b: Person) =>
      a.transit_Tolls_Amount.localeCompare(b.transit_Tolls_Amount),
  },
  {
    title: "Savings",
    key: "mobileNo",
    dataIndex: "savings",
    sorter: (a: Person, b: Person) => a.savings - b.savings,
  },
  {
    title: "Total Funds Available",
    key: "total_Funds_Available",
    dataIndex: "total_Funds_Available",
    sorter: (a: Person, b: Person) =>
      a.total_Funds_Available.localeCompare(b.total_Funds_Available),
  },
  // Actions Column
  // Actions Column
  {
    title: "Actions",
    key: "actions",
    render: (text: string, record: Person) => (
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item key="edit">
              <div className="dropdown-menu-item">
                <a
                  href={`http://194.163.134.15:8003/api/UserManagement/AccessListEdit/${record.name}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Edit
                </a>
                <i color="" className="ri-edit-2-line"></i>
              </div>
            </Menu.Item>
            <Menu.Item key="remove">
              <div className="dropdown-menu-item">
                <a
                  href={`http://194.163.134.15:8003/api/UserManagement/AccessListDelete/${record.name}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Remove
                </a>
                <i className="ri-delete-bin-line"></i>
              </div>
            </Menu.Item>
          </Menu>
        }
      >
        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
          >
            <circle cx="8" cy="4" r="1" />
            <circle cx="8" cy="8" r="1" />
            <circle cx="8" cy="12" r="1" />
          </svg>
        </a>
      </Dropdown>
    ),
  },
];

import React, { useState } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane, UncontrolledTooltip } from "reactstrap";
import { Link } from 'react-router-dom';
import classnames from "classnames";
import ARWPNestedTable from './ARWPNestedTable'
import ARWPListTable from './ARWPListTable';

const ARWPFiles = () => {
      // Animation Nav

      const [animationNavTab, setanimationNavTab] = useState("1");
      const animationNavToggle = (tab: any) => {
          if (animationNavTab !== tab) {
              setanimationNavTab(tab);
          }
      };
  return (
    <Row>
    <Col>
        <Card>
        <CardHeader>
                  <h4 className="card-title mb-0">Uploaded ARWP Files</h4>
                </CardHeader>
            <CardBody>
                <p className="text-muted">you can view the files as a full list or as grouped by agency</p>

                <Nav pills className="nav nav-pills animation-nav nav-justified gap-2 mb-3">
                    <NavItem>
                        <NavLink style={{ cursor: "pointer", border: 'primary' }} className={`border-primary ${classnames({ active: animationNavTab === "1" })}`}
 onClick={() => { animationNavToggle("1"); }} >
                           List by Agency
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink style={{ cursor: "pointer" }} className={`border-primary ${classnames({ active: animationNavTab === "2" })}`}
 onClick={() => { animationNavToggle("2"); }} >
                           List All
                        </NavLink>
                    </NavItem>
                </Nav>

                <TabContent activeTab={animationNavTab} className="text-muted">
                    <TabPane tabId="1" id="animation-agency">
                        <ARWPNestedTable/>
                    </TabPane>

                    <TabPane tabId="2" id="animation-list">
                        <ARWPListTable/>
                    </TabPane>
                </TabContent>
            </CardBody>
        </Card>
    </Col>
</Row>
  )
}

export default ARWPFiles
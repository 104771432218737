import React from 'react';
import { Tag, Dropdown, Menu } from 'antd';
import { Link } from 'react-router-dom';

interface ARWPFile {
  id: number;
  regionOrCounty: string;
  constituency: string;
  cityOrMunicipality: string;
  nationalParkOrGameReserve: string;
  itemNumber: number;
  roadId: string;
  sectionOrRoadName: string;
  surfaceType: string;
  plannedSectionLengthKm: number;
  amountKsh: number;
  arwpUploadDetailId: number;
  arwpUploadDetail: any; // Adjust this type accordingly
}

export const ARWPCOLUMNS = [
  {
    title: 'Region/County',
    dataIndex: 'regionOrCounty',
    key: 'regionOrCounty',
    sorter: (a: ARWPFile, b: ARWPFile) => a.regionOrCounty.localeCompare(b.regionOrCounty),
  },
  {
    title: 'Constituency',
    dataIndex: 'constituency',
    key: 'constituency',
    sorter: (a: ARWPFile, b: ARWPFile) => a.constituency.localeCompare(b.constituency),
  },
  {
    title: 'Road ID',
    dataIndex: 'roadId',
    key: 'roadId',
    sorter: (a: ARWPFile, b: ARWPFile) => a.roadId.localeCompare(b.roadId),
    render: (text: string, record: ARWPFile) => (
      <Link to={`/planning/arwp/workplan-activities/${record.id}`}>
        {text}
      </Link>
    ),
  },
  {
    title: 'Section/Road Name',
    dataIndex: 'sectionOrRoadName',
    key: 'sectionOrRoadName',
    sorter: (a: ARWPFile, b: ARWPFile) => a.sectionOrRoadName.localeCompare(b.sectionOrRoadName),
  },
  {
    title: 'Surface Type',
    dataIndex: 'surfaceType',
    key: 'surfaceType',
    sorter: (a: ARWPFile, b: ARWPFile) => a.surfaceType.localeCompare(b.surfaceType),
  },
  {
    title: 'Planned Section Length (km)',
    dataIndex: 'plannedSectionLengthKm',
    key: 'plannedSectionLengthKm',
    sorter: (a: ARWPFile, b: ARWPFile) => a.plannedSectionLengthKm - b.plannedSectionLengthKm,
  },
  {
    title: 'Amount (Ksh)',
    dataIndex: 'amountKsh',
    key: 'amountKsh',
    sorter: (a: ARWPFile, b: ARWPFile) => a.amountKsh - b.amountKsh,
    render: (amountKsh: number) => `Ksh ${amountKsh}`,
  },
];


interface summary {
  id: number;
  financialYear: string;
  authorityCode: string;
  totalKMPlanned: number;
  totalAmount: number;
}

export const Columns = [
    {
        title: 'Financial Year',
        dataIndex: 'financialYear',
        key: 'financialYear',
        sorter: (a: summary, b: summary) => a.financialYear.localeCompare(b.financialYear),
      },
      {
        title: 'Authority Code',
        dataIndex: 'authorityCode',
        key: 'authorityCode',
        sorter: (a: summary, b: summary) => a.authorityCode.localeCompare(b.authorityCode),
      },
      {
        title: 'totalKMPlanned',
        dataIndex: 'totalKMPlanned',
        key: 'totalKMPlanned',
        sorter: (a: summary, b: summary) => a.totalKMPlanned -b.totalKMPlanned,
      },
      {
        title: 'Total Amount',
        dataIndex: 'totalAmount',
        key: 'totalAmount',
        sorter: (a: summary, b: summary) =>  a.totalAmount - b.totalAmount,
      },
]
import { DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Table, Tooltip } from 'antd';
import { TableProps } from 'antd/lib/table';
import React, { ChangeEvent, useState } from 'react';
//@ts-ignore
import { CSVLink } from 'react-csv';
// import { SearchInput } from '../Forms';
// import  './Table.scss';

interface Props extends TableProps<any> {
  bordered?: boolean;
  hideSearch?: boolean;
  showDownload?: boolean;
  csvName?: string;
}

const TableComponent: React.FC<Props> = (props: any) => {
  const {
    csvName,
    showDownload,
    loading,
    columns,
    dataSource,
    pageSize,
    components,
    bordered,
    hideSearch,
    ...restProps
  } = props;

  const [filterTable, setFilterTable] = useState<any>(null);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const keys: string[] = columns.map((item: any) => item.dataIndex);

    const result = dataSource.filter((item: any) => {
      let isValue: boolean = false;

      keys.forEach((key: string) => {
        if (item[key] && String(item[key]).toLowerCase().includes(e.target.value.trim().toLowerCase())) {
          isValue = true;
        }
      });

      return isValue;
    });

    setFilterTable(result);
  };

  const headers = columns.map((column: any) => ({ label: column.label || column.title, key: column.key || '' }));

  return (
    <>
      <div
        style={{
          marginTop: 16,
          marginBottom: 16,
          display: 'flex',
          width: '100%',
          justifyContent: 'flex-end',
        }}
      >
        {/* {!hideSearch && (
          <SearchInput
            style={{
              border: '',
              height: 32,
              width: 200,
              marginRight: showDownload ? 16 : '',
            }}
            placeholder="Search"
            suffix={<SearchOutlined />}
            type="search"
            allowClear
            onChange={onChange}
            disabled={dataSource.length < 1}
          />
        )} */}
        {showDownload && (
          <CSVLink filename={(csvName || 'Table') + '.csv'} data={filterTable || dataSource} headers={headers}>
            <Tooltip title="Download CSV">
              <Button color='secondary' size={'middle'} shape={'round'} type="primary" icon={<DownloadOutlined />} />
            </Tooltip>
          </CSVLink>
        )}
      </div>
      <Table
      
        scroll={{ x: 736 }}
        size={'medium'}
        style={{ width: '100%', paddingTop: '3px' }}
        components={components}
        bordered={bordered}
        loading={loading}
        columns={columns}
        dataSource={filterTable ? filterTable : dataSource}
        pagination={{
          showTotal: (total, range) => `Showing ${range[1]} of ${total} entries`,
          showSizeChanger: true, // Enable option to change the number of entries per page
          pageSizeOptions: ['10', '20', '30', '50', '100'], // Specify available page sizes
          defaultPageSize: 10, // Set the default number of entries per page
        }}
        {...restProps}
      />
    </>
  );
};

export default TableComponent;

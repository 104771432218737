import React from 'react';
import { Space, Table, Tag, Dropdown, Menu } from 'antd';

interface AuditListViewModel {
  id: number;
  user_id: string;
  type: string;
  table_name: string;
  date_time: string;
  old_values: any;
  new_values: any;
  affected_columns: any;
  primary_key: string;
}

export const AUDIT_COLUMNS = [
  // {
  //   title: 'ID',
  //   key: 'id',
  //   dataIndex: 'id',
  //   sorter: (a: AuditListViewModel, b: AuditListViewModel) => a.id - b.id,
  // },
  {
    title: 'User ID',
    key: 'user_id',
    dataIndex: 'user_id',
    sorter: (a: AuditListViewModel, b: AuditListViewModel) => a.user_id.localeCompare(b.user_id),
  },
  {
    title: 'Type',
    key: 'type',
    dataIndex: 'type',
    sorter: (a: AuditListViewModel, b: AuditListViewModel) => a.type.localeCompare(b.type),
  },
  {
    title: 'Table Name',
    key: 'table_name',
    dataIndex: 'table_name',
    sorter: (a: AuditListViewModel, b: AuditListViewModel) => a.table_name.localeCompare(b.table_name),
  },
  {
    title: 'Date Time',
    key: 'date_time',
    dataIndex: 'date_time',
    sorter: (a: AuditListViewModel, b: AuditListViewModel) => a.date_time.localeCompare(b.date_time),
  },
  {
    title: 'Old Values',
    key: 'old_values',
    dataIndex: 'old_values',
    sorter: (a: AuditListViewModel, b: AuditListViewModel) => {
      // You can add custom logic for sorting old_values if needed
      // For example, if old_values are numbers, you can use a numeric comparison
      // return (parseInt(a.old_values) || 0) - (parseInt(b.old_values) || 0);
    },
  },
  {
    title: 'New Values',
    key: 'new_values',
    dataIndex: 'new_values',
    sorter: (a: AuditListViewModel, b: AuditListViewModel) => {
      // You can add custom logic for sorting new_values if needed
      // For example, if new_values are numbers, you can use a numeric comparison
      // return (parseInt(a.new_values) || 0) - (parseInt(b.new_values) || 0);
    },
  },
  {
    title: 'Affected Columns',
    key: 'affected_columns',
    dataIndex: 'affected_columns',
    sorter: (a: AuditListViewModel, b: AuditListViewModel) => {
      // You can add custom logic for sorting affected_columns if needed
      // For example, if affected_columns are numbers, you can use a numeric comparison
      // return (parseInt(a.affected_columns) || 0) - (parseInt(b.affected_columns) || 0);
    },
  },
  {
    title: 'Primary Key',
    key: 'primary_key',
    dataIndex: 'primary_key',
    sorter: (a: AuditListViewModel, b: AuditListViewModel) => a.primary_key.localeCompare(b.primary_key),
  },
  {
    title: 'Actions',
    key: 'actions',
    render: (text: string, record: AuditListViewModel) => (
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item key="edit">
              <div className="dropdown-menu-item">
                <a href={`http://example.com/edit/${record.id}`} target="_blank" rel="noopener noreferrer">
                  Edit
                </a>
                <i color='' className="ri-edit-2-line"></i>
              </div>
            </Menu.Item>
            <Menu.Item key="remove">
              <div className="dropdown-menu-item">
                <a href={`http://example.com/delete/${record.id}`} target="_blank" rel="noopener noreferrer">
                  Remove
                </a>
                <i className="ri-delete-bin-line"></i>
              </div>
            </Menu.Item>
          </Menu>
        }
      >
        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <circle cx="8" cy="4" r="1" />
            <circle cx="8" cy="8" r="1" />
            <circle cx="8" cy="12" r="1" />
          </svg>
        </a>
      </Dropdown>
    ),
  },
];

// action.js
import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  API_ERROR,
  SOCIAL_LOGIN,
  RESET_LOGIN_FLAG,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  LOGIN_USER_OTP_REQUIRED,
} from "./actionTypes";

export const loginUser = (username, password) => {
  console.log("Login User:", username, password);
  return {
    type: LOGIN_USER,
    payload: { username, password },
  };
};


export const loginSuccess = (user) => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};

export const socialLogin = (type, history) => {
  return {
    type: SOCIAL_LOGIN,
    payload: { type, history },
  };
};

export const resetLoginFlag = () => {
  return {
    type: RESET_LOGIN_FLAG,
  };
};

export const logoutUser = (history) => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  };
};

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  };
};

export const loginUserOTPRequired = (user) => {
  return {
    type: LOGIN_USER_OTP_REQUIRED,
    payload: user,
  };
};
interface WorkCategoryItem {
    financialYear: string;
    authorityCode: string;
    workCategory: string;
    totalKMPlanned: number;
    totalAmount: number;
  }
  
  export const Columns = [
    {
      title: 'Financial Year',
      dataIndex: 'financialYear',
      key: 'financialYear',
      sorter: (a: WorkCategoryItem, b: WorkCategoryItem) =>
        a.financialYear.localeCompare(b.financialYear),
    },
    {
      title: 'Authority Code',
      dataIndex: 'authorityCode',
      key: 'authorityCode',
      sorter: (a: WorkCategoryItem, b: WorkCategoryItem) =>
        a.authorityCode.localeCompare(b.authorityCode),
    },
    {
      title: 'Work Category',
      dataIndex: 'workCategory',
      key: 'workCategory',
      sorter: (a: WorkCategoryItem, b: WorkCategoryItem) =>
        a.workCategory.localeCompare(b.workCategory),
    },
    {
      title: 'Total KM Planned',
      dataIndex: 'totalKMPlanned',
      key: 'totalKMPlanned',
      sorter: (a: WorkCategoryItem, b: WorkCategoryItem) =>
        a.totalKMPlanned - b.totalKMPlanned,
    },
    {
      title: 'Total Amount',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      sorter: (a: WorkCategoryItem, b: WorkCategoryItem) =>
        a.totalAmount - b.totalAmount,
    },
  ];
  
import React from 'react';
import { Container } from "reactstrap";

//Import Breadcrumb
import BreadCrumb from '../../../Components/Common/BreadCrumb';

    const APRP = () => {
        document.title = "APRP | APRPMS";   //for meta title
        return (
            <>
                <div className="page-content">
                    <Container fluid={true}>
                        <BreadCrumb title="APRP" breadcrumbItem="APRP" />
                        
                            //write Html code or structure

                    </Container>
                </div>
            </>
        );
    }

export default APRP;
// Actions
export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";
export const GET_TEAM_DATA = "GET_TEAM_DATA";
export const DELETE_TEAM_DATA = "DELETE_TEAM_DATA";
export const DELETE_TEAM_DATA_SUCCESS = "DELETE_TEAM_DATA_SUCCESS";
export const DELETE_TEAM_DATA_FAIL = "DELETE_TEAM_DATA_FAIL";
export const ADD_TEAM_DATA = "ADD_TEAM_DATA";
export const ADD_TEAM_DATA_SUCCESS = "ADD_TEAM_DATA_SUCCESS";
export const ADD_TEAM_DATA_FAIL = "ADD_TEAM_DATA_FAIL";
export const UPDATE_TEAM_DATA = "UPDATE_TEAM_DATA";
export const UPDATE_TEAM_DATA_SUCCESS = "UPDATE_TEAM_DATA_SUCCESS";
export const UPDATE_TEAM_DATA_FAIL = "UPDATE_TEAM_DATA_FAIL";


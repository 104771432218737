// ARWPDetailTable.tsx
import React from 'react';
import Table from '../../../../Components/Tables/Table';

interface Props {
  parentColumns: any[];
  nestedColumns?: any[]; // Add a ? to make it optional
  dataSource: any[];
}

const ARWPDetailTable: React.FC<Props> = ({ parentColumns, nestedColumns, dataSource }) => {
  const formatData = (data: any) =>
    data.map((item: any, index: number) => ({
      ...item,
      key: index,
    }));

  // Define columns for the parent table
  const parentTableColumns = [...parentColumns];

  // Define inline styles to remove padding and margin
  const noMarginPaddingStyle = {
    margin: 0,
    padding: 0,
  };

  return (
    <Table
      dataSource={formatData(dataSource)}
      columns={parentTableColumns}
      expandable={{
        expandedRowRender: (record) => (
          // Check if there are constituencies and nestedColumns
          record.constituencies && nestedColumns && nestedColumns.length > 0 ? (
            // Create another nested table for constituencies
            <Table
              columns={nestedColumns}
              dataSource={record.constituencies}
              pagination={false}
              style={noMarginPaddingStyle}
              expandable={{
                expandedRowRender: (constituencyRecord) =>
                  // Render the innermost table for files
                  nestedColumns && nestedColumns.length > 0 ? (
                    <Table
                      columns={nestedColumns}
                      dataSource={constituencyRecord.files}
                      pagination={false}
                      style={noMarginPaddingStyle}
                    />
                  ) : null,
              }}
            />
          ) : null
        ),
        // Conditionally render the expand button based on the presence of constituencies
        rowExpandable: (record) => Boolean(record.constituencies && record.constituencies.length > 0),
      }}
    />
  );
};

export default ARWPDetailTable;

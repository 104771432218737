interface DataItem {
    financialYear: string;
    authorityCode: string;
    surfaceType: string;
    totalKMPlanned: number;
    totalAmount: number;
  }
  
  export const Columns = [
    {
      title: 'Financial Year',
      dataIndex: 'financialYear',
      key: 'financialYear',
      sorter: (a: DataItem, b: DataItem) => a.financialYear.localeCompare(b.financialYear),
    },
    {
      title: 'Authority Code',
      dataIndex: 'authorityCode',
      key: 'authorityCode',
      sorter: (a: DataItem, b: DataItem) => a.authorityCode.localeCompare(b.authorityCode),
    },
    {
      title: 'Surface Type',
      dataIndex: 'surfaceType',
      key: 'surfaceType',
      sorter: (a: DataItem, b: DataItem) => a.surfaceType.localeCompare(b.surfaceType),
    },
    {
      title: 'Total KM Planned',
      dataIndex: 'totalKMPlanned',
      key: 'totalKMPlanned',
      sorter: (a: DataItem, b: DataItem) => a.totalKMPlanned - b.totalKMPlanned,
    },
    {
      title: 'Total Amount',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      sorter: (a: DataItem, b: DataItem) => a.totalAmount - b.totalAmount,
    },
  ];
  
import { useState } from 'react';

export function useDeleteModal() {
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const showDeleteModal = () => {
    setIsDeleteModalVisible(true);
  };

  const hideDeleteModal = () => {
    setIsDeleteModalVisible(false);
  };

  return {
    isDeleteModalVisible,
    showDeleteModal,
    hideDeleteModal,
  };
}

import React from 'react'
import { Button, Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../../../Components/Common/BreadCrumb';
import DetailListTable from './DetailListTable';
import Overview from './Overview';

const ARWPDetail = () => {
  document.title = "Workplan Detail View | APRPMS ";   //for meta title
        return (
            <>
                <div className="page-content">
                    <Container fluid={true}>
                        <BreadCrumb title="Workplan Details" breadcrumbItem="Workplan Details" />
                        
                        <Row>
              <Col lg={12}>
                <Card>
                  <CardHeader>
                    <h4 className="card-title mb-0">Workplan Details</h4>
                  </CardHeader>
                  <CardBody>
                    <div id="customerList">
                      <Row className="g-4 mb-3">
                        {/* <Overview/> */}
                      </Row>

                      <div className="table-responsive table-card mt-3 mb-1">
                        <div className="table align-middle table-nowrap">
                         <DetailListTable/>   
                        </div>
                 
                      </div>

                   
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

                    </Container>
                </div>
            </>
        );
}

export default ARWPDetail
/* eslint-disable no-restricted-globals */
import React from 'react';
import {  Tag, Dropdown, Menu } from 'antd';


interface User {
  id: string;
  user_name: string;
  email_address: string;
  email_confirmed: boolean;
  mobile_no: string;
  phone_confirmed: boolean;
  directorate: string;
  status: string;
  isInAdministratorRole: boolean;
}

export const USERSCOLUMNS = [
  // {
  //   title: 'ID',
  //   key: 'id',
  //   dataIndex: 'id',
  //   sorter: (a: User, b: User) => a.id.localeCompare(b.id),
  // },
  {
    title: 'User Name',
    key: 'user_name',
    dataIndex: 'user_name',
    sorter: (a: User, b: User) => a.user_name.localeCompare(b.user_name),
  },
  {
    title: 'Email Address',
    key: 'email_address',
    dataIndex: 'email_address',
    sorter: (a: User, b: User) => a.email_address.localeCompare(b.email_address),
  },
  {
    title: 'Email Confirmed',
    key: 'email_confirmed',
    dataIndex: 'email_confirmed',
    sorter: (a: User) => a.email_confirmed ? 1 : -1,
    render: (emailConfirmed: boolean) => (
      <Tag color={emailConfirmed ? 'green' : 'red'}>
        {emailConfirmed ? 'Confirmed' : 'Not Confirmed'}
      </Tag>
    ),
  },
  {
    title: 'Mobile No',
    key: 'mobile_no',
    dataIndex: 'mobile_no',
    sorter: (a: User, b: User) => a.mobile_no.localeCompare(b.mobile_no),
  },
  {
    title: 'Phone Confirmed',
    key: 'phone_confirmed',
    dataIndex: 'phone_confirmed',
    sorter: (a: User ) => a.phone_confirmed ? 1 : -1,
    render: (phoneConfirmed: boolean) => (
      <Tag color={phoneConfirmed ? 'green' : 'red'}>
        {phoneConfirmed ? 'Confirmed' : 'Not Confirmed'}
      </Tag>
    ),
  },
  {
    title: 'Directorate',
    key: 'directorate',
    dataIndex: 'directorate',
    sorter: (a: User, b: User) => a.directorate.localeCompare(b.directorate),
  },
  {
    title: 'Status',
    key: 'status',
    dataIndex: 'status',
    sorter: (a: User, b: User) => a.status.localeCompare(b.status),
    render: (status: string) => (
      <>
        <Tag color={status === 'Deactivate' ? 'green' : 'red'}> {status === 'Deactivate' ? (
          <a  >
            Active
          </a>
        ) : (
          <a >
            Inactivate
          </a>
        )}</Tag>
      </>
    ),
  },
  
  {
    title: 'Administrator',
    key: 'isInAdministratorRole',
    dataIndex: 'isInAdministratorRole',
    sorter: (a: User) => a.isInAdministratorRole ? 1 : -1,
    render: (isInAdministratorRole: boolean) => (
      <Tag color={isInAdministratorRole ? 'blue' : 'gray'}>
        {isInAdministratorRole ? 'Yes' : 'No'}
      </Tag>
    ),
  },
  {
    title: 'Actions',
    key: 'actions',
    render: (text: string, record: User) => (
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item key="edit">
              <div className="dropdown-menu-item">
                <a href={`http://example.com/edit/${record.id}`} target="_blank" rel="noopener noreferrer">
                  Edit
                </a>
                <i color='' className="ri-edit-2-line"></i>
              </div>
            </Menu.Item>
            <Menu.Item key="remove">
              <div className="dropdown-menu-item">
                <a href={`http://example.com/delete/${record.id}`} target="_blank" rel="noopener noreferrer">
                  Remove
                </a>
                <i className="ri-delete-bin-line"></i>
              </div>
            </Menu.Item>
            <Menu.Item key="deactivate">
              {record.status === 'Deactivate' ? (
                <div className="dropdown-menu-item">
                  <a target="_blank" rel="noopener noreferrer">
                    Deactivate
                  </a>
                  <span className="mdi mdi-marker-cancel"></span>
                </div>
              ) : (
                <div className="dropdown-menu-item">
                  <a target="_blank" rel="noopener noreferrer">
                    Activate
                  </a>
                  <span className="mdi mdi-checkbox-marked-circle-auto-outline"></span>
                </div>
              )}
            </Menu.Item>
          </Menu>
        }
      >
        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <circle cx="8" cy="4" r="1" />
            <circle cx="8" cy="8" r="1" />
            <circle cx="8" cy="12" r="1" />
          </svg>
        </a>
      </Dropdown>
    ),
  }
];

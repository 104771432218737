// DetailListTable.tsx
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import ARWPDetailTable from './ARWPDetailTable';
import { ARWPCOLUMNS } from './Contants';

interface File {
  key: string;
  regionOrCounty: string;
  constituency: string; // Add constituency field
  files: any[];
}

const columns = [
  {
    title: 'County',
    dataIndex: 'regionOrCounty',
    key: 'regionOrCounty',
  },
  // {
  //   title: 'constituency', // Add constituency field
  //   dataIndex: 'constituency',
  //   key: 'constituency',
  // },
];

const DetailListTable = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState<File[]>([]);

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const response = await axios.get(`http://194.163.134.15:8007/api/v2/arwp/uploads/${id}`);
        const workplanDetails = response.data.workplanDetails || [];
        setDataSource(workplanDetails);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [id]);

  // Group data by regionOrCounty and constituency
  const groupedData: Record<string, Record<string, File[]>> = {};

  dataSource?.forEach((item) => {
    if (!groupedData[item.regionOrCounty]) {
      groupedData[item.regionOrCounty] = {};
    }

    if (!groupedData[item.regionOrCounty][item.constituency]) {
      groupedData[item.regionOrCounty][item.constituency] = [];
    }

    groupedData[item.regionOrCounty][item.constituency].push(item);
  });

  // Transform grouped data into an array of objects
  const nestedDataSource = Object.keys(groupedData).map((regionKey) => ({
    key: regionKey,
    regionOrCounty: regionKey,
    constituencies: Object.keys(groupedData[regionKey]).map((constituencyKey) => ({
      key: `${regionKey}_${constituencyKey}`,
      regionOrCounty: regionKey,
      constituency: constituencyKey,
      files: groupedData[regionKey][constituencyKey] || [],
    })),
  }));

  return (
    <ARWPDetailTable
      parentColumns={columns}
      nestedColumns={ARWPCOLUMNS}
      dataSource={nestedDataSource}
    />
  );
};

export default DetailListTable;
